import { gql, useQuery } from '@apollo/client'
import React, { PropsWithChildren } from 'react'
import { Link } from 'react-router-dom'
import { useStyletron } from 'styletron-react'
import { Company } from '../../models'
import PondOverview from '../../pond/overview'
import { margin } from '../../utils/css'

export const CURRENT_USER = gql`
  query currentUserQuery {
    currentUser {
      company {
        name
        noBranches
        noBastias
        noPonds
        noCages
        waterArea
        landArea
        overview {
          category
          weight
          amount
          estimate {
            weight
            amount
          }
        }
        branches {
          _id
          name
          code
          waterArea
          landArea
          overview {
            category
            weight
            amount
            estimate {
              weight
              amount
            }
          }
          bastias {
            _id
            name
            code
            waterArea
            landArea
            ponds {
              _id
              name
              code
              waterArea
              landArea
            }
          }
        }
      }
    }
  }
`

export default function HomeScreen(props: PropsWithChildren<{}>) {
  const [css] = useStyletron()
  const { loading, error, data } = useQuery<{
    currentUser: { company: Company }
  }>(CURRENT_USER)

  if (error) {
    return <div>Error!</div>
  }
  if (loading) {
    return <>...</>
  }

  if (!data) {
    return <div>No results found</div>
  }

  const {
    currentUser: { company },
  } = data

  const highlightedCss = css({
    fontSize: '4rem',
  })

  return (
    <div>
      <h1>{data.currentUser.company.name}</h1>
      <div
        className={css({
          display: 'grid',
          gap: '1rem',
          ...margin('2rem 0'),
          '@media (min-width: 720px)': {
            gridTemplateColumns: 'repeat(6, 1fr)',
          },
        })}
      >
        <div>
          <div className={highlightedCss}>{company.noBranches}</div>
          <div>Počet středisek</div>
        </div>
        <div>
          <div className={highlightedCss}>{company.noBastias}</div>
          <div>Počet bašt</div>
        </div>
        <div>
          <div className={highlightedCss}>{company.noPonds}</div>
          <div>Počet rybníků</div>
        </div>
        <div>
          <div className={highlightedCss}>{company.noCages}</div>
          <div>Počet sádek</div>
        </div>
        <div>
          <div className={highlightedCss}>
            {company.landArea.toFixed(4)}
            <small
              className={css({
                fontSize: '1.5rem',
              })}
            >
              ha
            </small>
          </div>
          <div>Katastrální výměra</div>
        </div>
        <div>
          <div className={highlightedCss}>
            {company.waterArea.toFixed(4)}
            <small
              className={css({
                fontSize: '1.5rem',
              })}
            >
              ha
            </small>
          </div>
          <div>Vodní plocha</div>
        </div>
      </div>
      <PondOverview items={company.overview} />
      <div>
        {company.branches.map((branch) => (
          <div
            className={css({
              marginTop: '1rem',
            })}
          >
            <div>
              <Link to={`/branch/${branch._id}`}>
                <h2>{branch.name}</h2>
              </Link>
            </div>
            <PondOverview items={branch.overview} />
          </div>
        ))}
      </div>
      <div>
        <>
          {data.currentUser.company.branches.map((branch) => (
            <>
              <div
                className={css({
                  display: 'grid',
                  gridTemplateColumns: '1fr 1fr 1fr 1fr',
                  background: 'var(--black-05)',
                  padding: '1rem',
                })}
              >
                <Link to={`/branch/${branch._id}`}>
                  <div>{branch.name}</div>
                </Link>
                <div>{branch.code}</div>
                <div>{branch.waterArea.toFixed(4)} ha</div>
                <div>{branch.landArea.toFixed(4)} ha</div>
              </div>
              {branch.bastias.map((bastia) => (
                <>
                  <div
                    className={css({
                      display: 'grid',
                      gridTemplateColumns: '1fr 1fr 1fr 1fr',
                      padding: '1rem',
                      fontWeight: 'bold',
                      borderBottom: 'solid 1px var(--black-10)',
                    })}
                  >
                    <Link to={`/bastia/${bastia._id}`}>
                      <div>{bastia.name}</div>
                    </Link>
                    <div>{bastia.code}</div>
                    <div>{bastia.waterArea.toFixed(4)} ha</div>
                    <div>{bastia.landArea.toFixed(4)} ha</div>
                  </div>
                  {bastia.ponds.map((pond) => (
                    <>
                      <div
                        className={css({
                          display: 'grid',
                          gridTemplateColumns: '1fr 1fr 1fr 1fr',
                          padding: '1rem',
                          borderBottom: 'solid 1px var(--black-10)',
                        })}
                      >
                        <Link to={`/pond/${pond._id}`}>
                          <div>{pond.name}</div>
                        </Link>
                        <div>{pond.code}</div>
                        <div>{pond.waterArea.toFixed(4)} ha</div>
                        <div>{pond.landArea.toFixed(4)} ha</div>
                      </div>
                    </>
                  ))}
                </>
              ))}
            </>
          ))}
        </>
      </div>
    </div>
  )
}
