import { Button, ButtonAppearance } from "@werein/components";
import { useFormikContext } from "formik";
import React, { PropsWithChildren, useEffect, useState } from "react";
import { useStyletron } from "styletron-react";
import CategorySelectFieldForm from "../category/category-select-field-form";
import ButtonForm from "../form/button-form";
import CheckboxField from "../form/checkbox-field";
import CheckboxFieldForm from "../form/checkbox-field-form";
import DatePickerFieldForm from "../form/date-picker-field-form";
import InputFieldForm from "../form/input-field-form";
import TextareaFieldForm from "../form/textarea-field-form";
import PondSelectFieldForm from "./pond-select-field-form";

export default function CycleForm(
  props: PropsWithChildren<{
    estimate?: boolean;
    edit?: boolean;
    onDelete?: () => void;
  }>
) {
  const [css] = useStyletron();
  const [addNote, setAddNote] = useState(false);

  const { values, setFieldValue, touched } = useFormikContext<{
    amount: number;
    weight: number;
    massUnit: number;
  }>();
  const { amount, weight, massUnit } = values;
  const { massUnit: massUnitTouched } = touched;

  useEffect(() => {
    if (!massUnitTouched) {
      setFieldValue("massUnit", weight / amount || 0);
    }
    if (amount < 0) {
      setFieldValue("in", false);
    }
  }, [amount, weight]);

  return (
    <div
      className={css({
        display: "grid",
        gap: "1rem",
        position: "sticky",
        bottom: 0,
        padding: "1rem 0",
        background: "white",
        "@media (min-width: 720px)": {
          gridTemplateColumns: props.estimate
            ? props.edit
              ? "1fr"
              : addNote
              ? "1fr 1fr 1fr 1fr 1fr 1fr 3fr 1fr"
              : "2fr 2fr 2fr 2fr 2fr 1fr 2fr"
            : props.edit
            ? "1fr"
            : addNote
            ? "1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 3fr 1fr"
            : "1fr 2fr 2fr 2fr 2fr 2fr 2fr 1fr 2fr",
        },
      })}
    >
      {!props.estimate && (
        <CheckboxFieldForm label="Nasazeno" name="in" autoHide />
      )}
      <DatePickerFieldForm label="Datum" name="date" autoHide />
      <CategorySelectFieldForm label="Kategorie" name="category" autoHide />
      <InputFieldForm
        suffix="ks"
        label="Počet"
        name="amount"
        numeric
        autoHide
      />
      <InputFieldForm
        suffix="kg"
        label="Hmotnost"
        name="weight"
        numeric
        autoHide
      />
      <InputFieldForm
        suffix="kg/ks"
        label="Váha kusu"
        name="massUnit"
        numeric
        autoHide
      />
      {!props.estimate && (
        <PondSelectFieldForm
          disabled={props.edit}
          label="Zdroj"
          name="pondId"
          autoHide
        />
      )}

      {!props.edit && (
        <CheckboxField
          name="x"
          label="Přidat poznámku"
          value={addNote}
          onChange={(v) => setAddNote(v)}
        />
      )}
      {(addNote || props.edit) && (
        <TextareaFieldForm name="note" label="Poznámka" />
      )}
      <div
        className={css({
          alignItems: "flex-end",
          display: "flex",
          margin: "5px 0",
        })}
      >
        <ButtonForm style={{ width: "100%", height: "44px" }}>
          {props.edit ? "Aktualizovat" : "Přidat"}
        </ButtonForm>
      </div>
      {props.edit && (
        <Button
          onClick={props.onDelete}
          appearance={ButtonAppearance.secondary}
        >
          Smazat
        </Button>
      )}
    </div>
  );
}
