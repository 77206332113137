import { gql, useMutation } from "@apollo/client";
import { Form, Formik, FormikHelpers } from "formik";
import React from "react";
import { useHistory } from "react-router";
import { useStyletron } from "styletron-react";
import * as Yup from "yup";
import { PondCycleEntryInput } from "../models";
import CycleForm from "./cycle-form";

export const validationSchema = Yup.object().shape({
  category: Yup.string().required("Doplňte kagetorii"),
});

const CREATE_BRANCH = gql`
  mutation createPondCycleEntry(
    $pondId: String!
    $cycleId: String!
    $input: PondCycleEntryInput!
  ) {
    createPondCycleEntry(pondId: $pondId, cycleId: $cycleId, input: $input) {
      _id
    }
  }
`;

interface CreateEntryFormProps {
  pondId: string;
  cycleId: string;
  refetch: any;
}

export default function CreateEntryForm(props: CreateEntryFormProps) {
  const [createPondCycleEntry] = useMutation(CREATE_BRANCH);
  const { push } = useHistory();
  const { pondId, cycleId } = props;
  const [css] = useStyletron();

  const onSubmit = async (
    values: PondCycleEntryInput & { in: boolean },
    formikHelpers: FormikHelpers<PondCycleEntryInput & { in: boolean }>
  ) => {
    const amount = values.in
      ? Math.abs(values.amount)
      : -Math.abs(values.amount);
    const weight = values.in
      ? Math.abs(values.weight)
      : -Math.abs(values.weight);

    const input: PondCycleEntryInput = {
      note: values.note,
      pondId: values.pondId,
      amount,
      date: values.date,
      weight,
      massUnit: values.massUnit,
      category: values.category,
    };

    try {
      const { data } = await createPondCycleEntry({
        variables: { input, pondId, cycleId },
      });

      await props.refetch();
      formikHelpers.resetForm();
    } catch (error) {
      formikHelpers.setStatus("ERROR");
    }
  };

  return (
    <Formik
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      initialValues={{
        in: true,
        date: new Date(),
        weight: 0,
        amount: 0,
        pondId: null as any,
        massUnit: 0,
        category: "",
      }}
    >
      <Form
        className={css({
          position: "sticky",
          top: 0,
          borderBottom: "solid 1px gray",
          "@media print": {
            display: "none",
          },
        })}
      >
        <CycleForm />
      </Form>
    </Formik>
  );
}
