import { ListItem as BaseListItem, ListItemLabel } from "baseui/list";
import React, { PropsWithChildren, ReactNode } from "react";
import { useHistory, useLocation } from "react-router";
import { StyleObject, useStyletron } from "styletron-react";

interface ListItemProps {
  onClick?: () => void;
  to?: string;
  artwork?: ReactNode;
  endEnhancer?: ReactNode;
  description?: string;
  sublist?: boolean;
}

export default function ListItem(props: PropsWithChildren<ListItemProps>) {
  const { push } = useHistory();
  const { pathname } = useLocation();
  const [css] = useStyletron();
  const navigate = () => {
    if (props.to) push(props.to);
  };
  const onClick = props.onClick || navigate;
  const style: StyleObject =
    props.onClick || props.to
      ? {
          backgroundColor: "var(--black-02)",
          ":hover": {
            cursor: "pointer",
            background: "var(--black-10)",
          },
          ...(props.to && pathname === props.to
            ? {
                backgroundColor: "var(--blue)",
                color: "white",
                ":hover": {
                  background: "var(--dark-blue)",
                },
              }
            : {}),
        }
      : {};

  return (
    <div onClick={onClick}>
      <BaseListItem
        sublist={props.sublist}
        artwork={props.artwork ? () => props.artwork : undefined}
        endEnhancer={props.endEnhancer ? () => props.endEnhancer : undefined}
        overrides={{
          Root: {
            style,
          },
        }}
      >
        <ListItemLabel sublist={props.sublist} description={props.description}>
          <div
            className={css({
              ...(props.to && pathname === props.to
                ? {
                    color: "white",
                  }
                : {}),
            })}
          >
            {props.children}
          </div>
        </ListItemLabel>
      </BaseListItem>
    </div>
  );
}
