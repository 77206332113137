import { sort } from 'ramda'
import React, { Fragment, PropsWithChildren } from 'react'
import { useStyletron } from 'styletron-react'
import { Category, PondCycleEstimate, PondOverviewEstimate, PondOverview as PondOverviewType } from '../models'
import { subset } from '../utils/categories'
import { formatNumber } from '../utils/numbers'

const diff = function (a: any, b: any) {
  return new Date(b.date).getTime() - new Date(a.date).getTime()
}

function PondOverViewLine<
  T extends {
    estimate?: PondOverviewEstimate
    weight?: number | null
    amount?: number | null
    category?: string | null
    sub?: boolean
    plusAmount?: number | null
    minusAmount?: number | null
    plusWeight?: number | null
    minusWeight?: number | null
  }
>(props: T) {
  const [css] = useStyletron()

  const { weight, amount, category, sub, plusAmount, minusAmount, plusWeight, minusWeight, estimate } = props

  if (weight || amount || estimate?.weight || estimate?.amount) {
    return (
      <tr>
        <td
          className={css({
            fontWeight: sub ? 500 : 'bold',
            fontSize: sub ? '0.8rem' : '1rem',
            padding: '0.5rem 0',
            borderBottom: 'solid 1px var(--black-20)',
            lineHeight: '2rem',
          })}
        >
          {category}
        </td>
        <td
          className={css({
            padding: '0.5rem 0',
            fontSize: sub ? '0.8rem' : '1rem',
            borderBottom: 'solid 1px var(--black-20)',
            lineHeight: '2rem',
            width: '100px',
            fontWeight: 'bold',
          })}
        >
          {estimate?.amount !== 0 ? estimate?.amount || '-' : 0}ks
        </td>
        <td
          className={css({
            padding: '0.5rem 0',
            fontSize: sub ? '0.8rem' : '1rem',
            borderBottom: 'solid 1px var(--black-20)',
            lineHeight: '2rem',
            width: '100px',
          })}
        >
          {amount}ks
        </td>
        <td
          className={css({
            marginLeft: '1rem',
            fontSize: '0.6rem',
            padding: '0.5rem 0',
            borderBottom: 'solid 1px var(--black-20)',
            lineHeight: '2rem',
            width: '50px',
          })}
        >
          +{plusAmount}ks
        </td>
        <td
          className={css({
            marginLeft: '1rem',
            fontSize: '0.6rem',
            padding: '0.5rem 0',
            borderBottom: 'solid 1px var(--black-20)',
            lineHeight: '2rem',
            width: '50px',
          })}
        >
          {minusAmount}ks
        </td>
        <td
          className={css({
            padding: '0.5rem 0',
            fontSize: sub ? '0.8rem' : '1rem',
            borderBottom: 'solid 1px var(--black-20)',
            lineHeight: '2rem',
            fontWeight: 'bold',
          })}
        >
          {estimate?.weight !== 0 ? formatNumber(estimate?.weight) || '-' : 0}kg
        </td>
        <td
          className={css({
            padding: '0.5rem 0',
            fontSize: sub ? '0.8rem' : '1rem',
            borderBottom: 'solid 1px var(--black-20)',
            lineHeight: '2rem',
          })}
        >
          {formatNumber(weight)}kg
        </td>
        <td
          className={css({
            marginLeft: '1rem',
            fontSize: '0.6rem',
            padding: '0.5rem 0',
            borderBottom: 'solid 1px var(--black-20)',
            lineHeight: '2rem',
            width: '50px',
          })}
        >
          +{formatNumber(plusWeight)}kg
        </td>
        <td
          className={css({
            marginLeft: '1rem',
            fontSize: '0.6rem',
            padding: '0.5rem 0',
            borderBottom: 'solid 1px var(--black-20)',
            lineHeight: '2rem',
            width: '50px',
          })}
        >
          {formatNumber(minusWeight)}kg
        </td>
      </tr>
    )
  }

  return <Fragment />
}

export default function PondOverviewExcel(
  props: PropsWithChildren<{
    items: PondOverviewType[]
    // estimates?: PondCycleEstimate[]
  }>
) {
  const [css] = useStyletron()
  // const { estimates = [] } = props
  const estimates = []

  return (
    <table
      className={css({
        width: '100%',
        background: 'var(--black-05)',
      })}
    >
      <tr role="row" className={css({ display: 'contents' })}>
        <th
          className={css({
            fontWeight: 'bold',
            padding: '0.5rem 0',
            borderBottom: 'solid 1px var(--black-20)',
            lineHeight: '2rem',
            textAlign: 'start',
            // width: "25%",
          })}
        >
          Kategorie
        </th>
        <th
          className={css({
            fontWeight: 'bold',
            padding: '0.5rem 0',
            borderBottom: 'solid 1px var(--black-20)',
            lineHeight: '2rem',
            textAlign: 'start',
            // width: "25%",
          })}
        >
          Odhad kusů
        </th>
        <th
          className={css({
            fontWeight: 'bold',
            padding: '0.5rem 0',
            borderBottom: 'solid 1px var(--black-20)',
            lineHeight: '2rem',
            textAlign: 'start',
            // width: "25%",
          })}
        >
          Počet kusů
        </th>
        <th
          className={css({
            fontWeight: 'bold',
            padding: '0.5rem 0',
            borderBottom: 'solid 1px var(--black-20)',
            lineHeight: '2rem',
            textAlign: 'start',
            // width: "10%",
          })}
        ></th>
        <th
          className={css({
            fontWeight: 'bold',
            padding: '0.5rem 0',
            borderBottom: 'solid 1px var(--black-20)',
            lineHeight: '2rem',
            textAlign: 'start',
            // width: "50px",
          })}
        ></th>
        <th
          className={css({
            fontWeight: 'bold',
            padding: '0.5rem 0',
            borderBottom: 'solid 1px var(--black-20)',
            lineHeight: '2rem',
            textAlign: 'start',
            // width: "25%",
          })}
        >
          Odhad hmotnost
        </th>
        <th
          className={css({
            fontWeight: 'bold',
            padding: '0.5rem 0',
            borderBottom: 'solid 1px var(--black-20)',
            lineHeight: '2rem',
            textAlign: 'start',
            // width: "25%",
          })}
        >
          Hmotnost
        </th>
        <th
          className={css({
            fontWeight: 'bold',
            padding: '0.5rem 0',
            borderBottom: 'solid 1px var(--black-20)',
            lineHeight: '2rem',
            textAlign: 'start',
            // width: "50px",
          })}
        ></th>
        <th
          className={css({
            fontWeight: 'bold',
            padding: '0.5rem 0',
            borderBottom: 'solid 1px var(--black-20)',
            lineHeight: '2rem',
            textAlign: 'start',
            // width: "50px",
          })}
        ></th>
      </tr>
      {Object.keys(Category).map((key) => {
        const subcategories = subset(key)
        const item = props.items.find((c) => c.category === key)
        // const sorted = sort(diff, estimates)

        if (subcategories.length === 1) {
          // const estimate = sorted.find((e) => e.category === item?.category)

          return (
            <PondOverViewLine
              estimate={item?.estimate as PondOverviewEstimate}
              category={item?.category}
              amount={item?.amount}
              weight={item?.weight}
              plusAmount={item?.plusAmount}
              minusAmount={item?.minusAmount}
              plusWeight={item?.plusWeight}
              minusWeight={item?.minusWeight}
            />
          )
        }

        // const estimate = subcategories.reduce(
        //   (prev, curr) => ({
        //     weight: prev.weight + (sorted.find((e) => e.category === curr)?.weight || 0),
        //     amount: prev.amount + (sorted.find((e) => e.category === curr)?.amount || 0),
        //   }),
        //   { weight: 0, amount: 0 }
        // )

        return (
          <>
            <PondOverViewLine
              estimate={item?.estimate as PondOverviewEstimate}
              category={item?.category}
              amount={item?.amount}
              weight={item?.weight}
              plusAmount={item?.plusAmount}
              minusAmount={item?.minusAmount}
              plusWeight={item?.plusWeight}
              minusWeight={item?.minusWeight}
            />
            {subcategories.map((sub) => {
              const subItem = props.items.find((c) => c.category === sub)

              // const subEstimate = sorted.find((e) => e.category === subItem?.category)

              return (
                <PondOverViewLine
                  sub
                  estimate={subItem?.estimate as PondOverviewEstimate}
                  category={subItem?.category}
                  amount={subItem?.amount}
                  weight={subItem?.weight}
                  plusAmount={subItem?.plusAmount}
                  minusAmount={subItem?.minusAmount}
                  plusWeight={subItem?.plusWeight}
                  minusWeight={subItem?.minusWeight}
                />
              )
            })}
          </>
        )
      })}
    </table>
  )
}
