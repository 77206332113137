import React from "react";
import BastiaSelectFieldForm from "../app/form/bastia-select-field-form";
import CheckboxFieldForm from "../form/checkbox-field-form";
import InputFieldForm from "../form/input-field-form";

export default function PondForm() {
  return (
    <div>
      <CheckboxFieldForm label="Sádky" name="cages" />
      <BastiaSelectFieldForm label="Bašta" name="bastiaId" />
      <InputFieldForm label="Název" name="name" />
      <InputFieldForm label="Označení" name="code" />
      <InputFieldForm label="Vodní plocha" name="waterArea" numeric />
      <InputFieldForm label="Katastrální výměra" name="landArea" numeric />
    </div>
  );
}
