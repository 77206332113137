export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
  DateTime: any;
};

export enum Category {
  Ks = 'Ks',
  Khl = 'Khl',
  Ab = 'Ab',
  L = 'L',
  Ca = 'Ca',
  S = 'S',
  Su = 'Su',
  Tb = 'Tb',
  Tp = 'Tp',
  Cv = 'Cv',
  Ka = 'Ka',
  Or = 'Or',
  Pd = 'Pd',
  Si = 'Si',
  Br = 'Br',
  Futr = 'Futr',
  Ma = 'Ma',
  Pe = 'Pe',
  Je = 'Je',
  U = 'U'
}

export enum CategoryInput {
  K0S = 'K0S',
  KrS = 'KrS',
  K1S = 'K1S',
  K2S = 'K2S',
  K3S = 'K3S',
  K4S = 'K4S',
  Kts = 'KTS',
  Kvs = 'KVS',
  KxS = 'KxS',
  K0Hl = 'K0Hl',
  KrHl = 'KrHl',
  K1Hl = 'K1Hl',
  K2Hl = 'K2Hl',
  K3Hl = 'K3Hl',
  K4Hl = 'K4Hl',
  KtHl = 'KTHl',
  KvHl = 'KVHl',
  KxHl = 'KxHl',
  Ab0 = 'Ab0',
  Ab1 = 'Ab1',
  Ab2 = 'Ab2',
  Ab3 = 'Ab3',
  AbT = 'AbT',
  Abx = 'Abx',
  L0 = 'L0',
  L1 = 'L1',
  L2 = 'L2',
  L3 = 'L3',
  Lt = 'LT',
  Ca0 = 'Ca0',
  Car = 'Car',
  Ca1 = 'Ca1',
  Ca2 = 'Ca2',
  Ca3 = 'Ca3',
  CaT = 'CaT',
  Cax = 'Cax',
  S0 = 'S0',
  Sr = 'Sr',
  S1 = 'S1',
  S2 = 'S2',
  S3 = 'S3',
  St = 'ST',
  Su = 'Su',
  Su0 = 'Su0',
  Su1 = 'Su1',
  Su2 = 'Su2',
  Su3 = 'Su3',
  SuT = 'SuT',
  Tb = 'Tb',
  Tb0 = 'Tb0',
  Tb1 = 'Tb1',
  Tb2 = 'Tb2',
  Tb3 = 'Tb3',
  TbT = 'TbT',
  Tp = 'Tp',
  Tp0 = 'Tp0',
  Tp1 = 'Tp1',
  Tp2 = 'Tp2',
  Tp3 = 'Tp3',
  TpT = 'TpT',
  Cv = 'Cv',
  Ka = 'Ka',
  KaT = 'KaT',
  Or = 'Or',
  Pd = 'Pd',
  Si = 'Si',
  Br = 'Br',
  Futr = 'Futr',
  Ma = 'Ma',
  Pe = 'Pe',
  Je = 'Je',
  U = 'U'
}



export type Account = {
  __typename?: 'Account';
  _id: Scalars['ID'];
  authId: Scalars['String'];
  avatar?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  company: Company;
  branches: Array<Branch>;
};

export type Company = {
  __typename?: 'Company';
  _id: Scalars['String'];
  name: Scalars['String'];
  branches: Array<Branch>;
  noBranches: Scalars['Int'];
  noBastias: Scalars['Int'];
  noPonds: Scalars['Int'];
  noCages: Scalars['Int'];
  waterArea: Scalars['Float'];
  landArea: Scalars['Float'];
  overview: Array<PondOverview>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};


export type CompanyOverviewArgs = {
  date?: Maybe<Scalars['String']>;
};

export type Branch = {
  __typename?: 'Branch';
  _id: Scalars['String'];
  name: Scalars['String'];
  code: Scalars['String'];
  company: Company;
  bastias: Array<Bastia>;
  noBastias: Scalars['Int'];
  noPonds: Scalars['Int'];
  noCages: Scalars['Int'];
  waterArea: Scalars['Float'];
  landArea: Scalars['Float'];
  overview: Array<PondOverview>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};


export type BranchOverviewArgs = {
  date?: Maybe<Scalars['String']>;
};

export type BranchInput = {
  name: Scalars['String'];
  code: Scalars['String'];
};

export type Bastia = {
  __typename?: 'Bastia';
  _id: Scalars['String'];
  name: Scalars['String'];
  code: Scalars['String'];
  branch: Branch;
  ponds: Array<Pond>;
  noPonds: Scalars['Int'];
  noCages: Scalars['Int'];
  waterArea: Scalars['Float'];
  landArea: Scalars['Float'];
  overview: Array<PondOverview>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};


export type BastiaOverviewArgs = {
  date?: Maybe<Scalars['String']>;
};

export type BastiaInput = {
  branchId: Scalars['String'];
  name: Scalars['String'];
  code: Scalars['String'];
};

export type PondCycleEntry = {
  __typename?: 'PondCycleEntry';
  _id: Scalars['String'];
  date: Scalars['DateTime'];
  weight: Scalars['Float'];
  amount: Scalars['Int'];
  category: Scalars['String'];
  pond?: Maybe<Pond>;
  cycle?: Maybe<PondCycle>;
  entryId?: Maybe<Scalars['String']>;
  massUnit: Scalars['Float'];
  note?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type PondCycleEntryInput = {
  pondId?: Maybe<Scalars['String']>;
  date: Scalars['Date'];
  weight: Scalars['Float'];
  amount: Scalars['Int'];
  massUnit: Scalars['Float'];
  category: Scalars['String'];
  note?: Maybe<Scalars['String']>;
};

export type PondCycleEstimate = {
  __typename?: 'PondCycleEstimate';
  _id: Scalars['String'];
  date: Scalars['DateTime'];
  weight: Scalars['Float'];
  amount: Scalars['Int'];
  category: Scalars['String'];
  massUnit: Scalars['Float'];
  note?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type PondCycleEstimateInput = {
  date: Scalars['Date'];
  weight: Scalars['Float'];
  amount: Scalars['Int'];
  massUnit: Scalars['Float'];
  category: Scalars['String'];
  note?: Maybe<Scalars['String']>;
};

export type PondCycleEntryUpdateInput = {
  pondId?: Maybe<Scalars['String']>;
  date: Scalars['Date'];
  weight: Scalars['Float'];
  amount: Scalars['Int'];
  massUnit: Scalars['Float'];
  category: Scalars['String'];
  note?: Maybe<Scalars['String']>;
};

export type PondOverviewEstimate = {
  __typename?: 'PondOverviewEstimate';
  weight?: Maybe<Scalars['Float']>;
  amount?: Maybe<Scalars['Int']>;
};

export type PondOverview = {
  __typename?: 'PondOverview';
  category?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['Float']>;
  amount?: Maybe<Scalars['Int']>;
  plusAmount?: Maybe<Scalars['Int']>;
  minusAmount?: Maybe<Scalars['Int']>;
  plusWeight?: Maybe<Scalars['Float']>;
  minusWeight?: Maybe<Scalars['Float']>;
  estimate?: Maybe<PondOverviewEstimate>;
};

export type PondCycle = {
  __typename?: 'PondCycle';
  _id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  closedAt?: Maybe<Scalars['DateTime']>;
  entries: Array<PondCycleEntry>;
  estimates: Array<PondCycleEstimate>;
  overview: Array<PondOverview>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};


export type PondCycleOverviewArgs = {
  date?: Maybe<Scalars['String']>;
};

export type Pond = {
  __typename?: 'Pond';
  _id: Scalars['String'];
  deleted?: Maybe<Scalars['Boolean']>;
  cages: Scalars['Boolean'];
  name: Scalars['String'];
  code: Scalars['String'];
  landArea: Scalars['Float'];
  waterArea: Scalars['Float'];
  bastia: Bastia;
  PondCycle: PondCycle;
  cycle: PondCycle;
  cycles: Array<PondCycle>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  historicCycle: PondCycle;
};


export type PondHistoricCycleArgs = {
  id: Scalars['String'];
};

export type PondInput = {
  bastiaId: Scalars['String'];
  name: Scalars['String'];
  code: Scalars['String'];
  cages?: Maybe<Scalars['Boolean']>;
  waterArea?: Maybe<Scalars['Float']>;
  landArea?: Maybe<Scalars['Float']>;
};

export type Query = {
  __typename?: 'Query';
  currentUser: Account;
  branch: Branch;
  branches: Array<Branch>;
  bastia: Bastia;
  pond: Pond;
  pondCycleOverview: Array<PondOverview>;
};


export type QueryBranchArgs = {
  id: Scalars['String'];
};


export type QueryBastiaArgs = {
  id: Scalars['String'];
};


export type QueryPondArgs = {
  id: Scalars['String'];
};


export type QueryPondCycleOverviewArgs = {
  pondId: Scalars['String'];
  cycleId?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  closePondCycle?: Maybe<PondCycle>;
  createBranch: Branch;
  createBastia: Bastia;
  createPond: Pond;
  updatePond: Pond;
  deletePond: Pond;
  updateBastia: Bastia;
  updateBranch: Branch;
  createPondCycleEntry: Pond;
  createPondCycleEstimate: Pond;
  updatePondCycleEntry: Pond;
  deletePondCycleEntry: Pond;
};


export type MutationClosePondCycleArgs = {
  id: Scalars['String'];
  name: Scalars['String'];
};


export type MutationCreateBranchArgs = {
  branchInput?: Maybe<BranchInput>;
};


export type MutationCreateBastiaArgs = {
  bastiaInput?: Maybe<BastiaInput>;
};


export type MutationCreatePondArgs = {
  pondInput?: Maybe<PondInput>;
};


export type MutationUpdatePondArgs = {
  id?: Maybe<Scalars['String']>;
  pondInput?: Maybe<PondInput>;
};


export type MutationDeletePondArgs = {
  id?: Maybe<Scalars['String']>;
};


export type MutationUpdateBastiaArgs = {
  id?: Maybe<Scalars['String']>;
  bastiaInput?: Maybe<BastiaInput>;
};


export type MutationUpdateBranchArgs = {
  id?: Maybe<Scalars['String']>;
  branchInput?: Maybe<BranchInput>;
};


export type MutationCreatePondCycleEntryArgs = {
  pondId: Scalars['String'];
  cycleId: Scalars['String'];
  input?: Maybe<PondCycleEntryInput>;
};


export type MutationCreatePondCycleEstimateArgs = {
  pondId: Scalars['String'];
  cycleId: Scalars['String'];
  input?: Maybe<PondCycleEstimateInput>;
};


export type MutationUpdatePondCycleEntryArgs = {
  pondId: Scalars['String'];
  cycleId: Scalars['String'];
  id: Scalars['String'];
  inverseId?: Maybe<Scalars['String']>;
  inversePondId?: Maybe<Scalars['String']>;
  inverseCycleId?: Maybe<Scalars['String']>;
  input?: Maybe<PondCycleEntryInput>;
};


export type MutationDeletePondCycleEntryArgs = {
  pondId: Scalars['String'];
  cycleId: Scalars['String'];
  id: Scalars['String'];
  inverseId?: Maybe<Scalars['String']>;
  inversePondId?: Maybe<Scalars['String']>;
  inverseCycleId?: Maybe<Scalars['String']>;
};
