import { gql, useQuery } from "@apollo/client";
import { OptgroupsT, Select, SelectProps } from "baseui/select";
import { flatten, mergeAll } from "ramda";
import React from "react";
import { useUser } from "../app/components/layout";
import { Branch } from "../models";

const GET_BRANCHES = gql`
  query branches {
    branches {
      _id
      name
      bastias {
        _id
        name
      }
    }
  }
`;

export type BastiaSelectProps = Omit<
  SelectProps,
  "options" | "isLoading" | "value"
> & { value?: string };

export default function BastiaSelect(props: BastiaSelectProps) {
  const { error, loading, data } = useQuery<{
    branches: [Branch];
  }>(GET_BRANCHES);
  const { currentUser } = useUser();

  if (error) {
    return <>Error</>;
  }

  const { value, ...rest } = props;
  const currentUserBranches = currentUser.branches.map((b) => b._id);

  const optionsGrouped =
    data?.branches
      .filter((b) =>
        currentUserBranches.length === 0
          ? true
          : currentUserBranches.includes(b._id)
      )
      .map((branch) => ({
        [branch.name]: branch.bastias.map((bastia) => ({
          label: bastia.name,
          id: bastia._id,
        })),
      })) || [];

  const flatOptions =
    data?.branches
      .filter((b) =>
        currentUserBranches.length === 0
          ? true
          : currentUserBranches.includes(b._id)
      )
      .map((branch) =>
        branch.bastias.map((bastia) => ({ label: bastia.name, id: bastia._id }))
      ) || [];

  const ungruped = flatten(flatOptions);

  const options: OptgroupsT = {
    __ungrouped: [],
    ...mergeAll(optionsGrouped),
  };

  const selectValue = ungruped.find((option) => option && option.id === value);

  return (
    <Select
      multi={false}
      clearable={false}
      isLoading={loading}
      options={options}
      placeholder="Vyberte baštu"
      value={selectValue ? [selectValue] : undefined}
      {...rest}
    />
  );
}
