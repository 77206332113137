import { Auth0Provider } from "@auth0/auth0-react";
import { lightTheme } from "@werein/components";
import { BaseProvider, createLightTheme } from "baseui";
import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { Client as Styletron } from "styletron-engine-atomic";
import { Provider as StyletronProvider } from "styletron-react";
import ApolloProvider from "./apollo-provider";
import App from "./App";
import MapkitProvider from "./components/maps/mapkit-provider";
import "./index.css";
import reportWebVitals from "./reportWebVitals";

const engine = new Styletron();

const theme = createLightTheme(
  {
    primary: "#24252a",
  },
  {
    colors: {
      buttonPrimaryFill: "#1e8bc3",
    },
    borders: {
      useRoundedCorners: true,
      buttonBorderRadius: lightTheme.overrides.borders.radius300,
    },
  }
);

ReactDOM.render(
  // <React.StrictMode>
  <StyletronProvider value={engine} debugAfterHydration>
    <BaseProvider theme={theme}>
      <BrowserRouter>
        <Suspense fallback="">
          <Auth0Provider
            domain="fishermanapp.eu.auth0.com"
            clientId="RbdE06FdeywDLkUyxiHTZCQ1K6lXdRo1"
            redirectUri={window.location.origin}
          >
            <ApolloProvider>
              <MapkitProvider>
                <App />
              </MapkitProvider>
            </ApolloProvider>
          </Auth0Provider>
        </Suspense>
      </BrowserRouter>
    </BaseProvider>
  </StyletronProvider>,
  // </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
