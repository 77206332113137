import { useStyletron } from "baseui";
import React from "react";
import List from "./list";

export default function Menu() {
  const [css] = useStyletron();

  return (
    <>
      {/* <Select
        options={options}
        value={value && [value]}
        placeholder="Vyberte středisko"
        onChange={(params) => push(`/branch/${params.option?.id}`)}
      /> */}
      <List />
    </>
  );
}
