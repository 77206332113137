import { gql, useQuery } from "@apollo/client";
import React, { createContext, PropsWithChildren, useContext } from "react";
import { useStyletron } from "styletron-react";
import { Account, Bastia, Branch, Company, Pond } from "../../models";
import Header from "./header";
import Menu from "./menu";

export const CurrentUserAccountContext = createContext<Account | undefined>(
  undefined
);

export const CURRENT_USER = gql`
  query currentUserQuery {
    currentUser {
      company {
        _id
        name
      }
      branches {
        _id
      }
    }
  }
`;

export function useUser() {
  const currentUser = useContext(CurrentUserAccountContext);

  function can({
    pond,
    branch,
    bastia,
    company,
  }: {
    pond?: Pond;
    branch?: Branch;
    bastia?: Bastia;
    company?: Company;
  }) {
    if (currentUser?.branches.length === 0) {
      if (company) return company._id === currentUser.company._id;
      if (branch) return branch.company._id === currentUser.company._id;
      if (bastia) return bastia.branch.company._id === currentUser.company._id;
      if (pond)
        return pond.bastia.branch.company._id === currentUser.company._id;
    }

    if (currentUser) {
      const branchesIds = currentUser.branches.map((b) => b._id);
      if (company) return company._id === currentUser.company._id;
      if (branch) return branchesIds.includes(branch._id);
      if (bastia) return branchesIds.includes(bastia.branch._id);
      if (pond) return branchesIds.includes(pond.bastia.branch._id);
    }

    return false;
  }

  return {
    can,
    currentUser: currentUser as Account,
  };
}

export default function Layout(props: PropsWithChildren<{}>) {
  const [css] = useStyletron();
  const { loading, error, data } =
    useQuery<{ currentUser: Account }>(CURRENT_USER);

  if (error) {
    return <div>Error!</div>;
  }
  if (loading) {
    return <>...</>;
  }

  if (!data) {
    return <div>No results found</div>;
  }

  return (
    <CurrentUserAccountContext.Provider value={data.currentUser}>
      <Header title={data.currentUser.company.name} />
      <div
        className={css({
          "@media (min-width: 720px)": {
            display: "grid",
            gridTemplateColumns: "300px auto",
          },
        })}
      >
        <div>
          <Menu />
        </div>
        <div
          className={css({
            padding: "1rem",
          })}
        >
          {props.children}
        </div>
      </div>
    </CurrentUserAccountContext.Provider>
  );
}
