import { gql, useQuery } from "@apollo/client";
import { Option, Select, SelectProps } from "baseui/select";
import React from "react";
import { useUser } from "../app/components/layout";
import { Branch } from "../models";

const GET_BRANCHES = gql`
  query branches {
    branches {
      _id
      name
    }
  }
`;

export type BranchSelectProps = Omit<
  SelectProps,
  "options" | "isLoading" | "value"
> & { value?: string };

export default function BranchSelect(props: BranchSelectProps) {
  const { currentUser } = useUser();
  const { error, loading, data } = useQuery<{
    branches: [Branch];
  }>(GET_BRANCHES);

  if (error) {
    return <>Error</>;
  }

  const { value, ...rest } = props;
  const currentUserBranches = currentUser.branches.map((b) => b._id);

  const options: Option[] =
    data?.branches
      .filter((b) =>
        currentUserBranches.length === 0
          ? true
          : currentUserBranches.includes(b._id)
      )
      .map((branch) => ({ label: branch.name, id: branch._id })) || [];

  const selectValue = options.filter((option) => option.id === value);

  return (
    <Select
      isLoading={loading}
      options={options}
      placeholder="Vyberte společnost"
      value={selectValue}
      {...rest}
    />
  );
}
