import { useAuth0 } from "@auth0/auth0-react";
import { Button, ButtonAppearance, NavButton } from "@werein/components";
import {
  ALIGN,
  HeaderNavigation,
  StyledNavigationItem,
  StyledNavigationList,
} from "baseui/header-navigation";
import { StatefulPopover } from "baseui/popover";
import * as React from "react";
import * as Icons from "react-feather";
import { useStyletron } from "styletron-react";
import { useUser } from "./layout";
import ListItem from "./list-item";

interface HeaderProps {
  title: string;
}

export default function Header(props: HeaderProps) {
  const [css] = useStyletron();
  const { logout } = useAuth0();
  const { currentUser } = useUser();
  const isAdmin = currentUser.branches.length === 0;
  return (
    <HeaderNavigation>
      <StyledNavigationList $align={ALIGN.left}>
        <StyledNavigationItem>
          <NavButton appearance={ButtonAppearance.minimal} to="/">
            {props.title}
          </NavButton>
        </StyledNavigationItem>
      </StyledNavigationList>
      <StyledNavigationList $align={ALIGN.center} />
      <StyledNavigationList $align={ALIGN.right}>
        <div
          className={css({
            display: "grid",
            gridTemplateColumns: "2fr 2fr 2fr 1fr",
            gap: "0.5rem",
            paddingRight: "1rem",
            "@media (max-width: 720px)": {
              display: "none",
            },
          })}
        >
          {isAdmin && (
            <NavButton to="/branches/create">Přidat středisko</NavButton>
          )}
          <NavButton to="/bastias/create">Přidat baštu</NavButton>
          <NavButton to="/ponds/create">Přidat rybník</NavButton>
          <Button
            appearance={ButtonAppearance.outline}
            onClick={() => logout()}
          >
            <Icons.LogOut />
          </Button>
        </div>
        <div
          className={css({
            "@media (min-width: 720px)": {
              display: "none",
            },
          })}
        >
          <StatefulPopover
            content={() => (
              <div>
                <ListItem to="/branches/create">Přidat středisko</ListItem>
                <ListItem to="/bastias/create">Přidat baštu</ListItem>
                <ListItem to="/ponds/create">Přidat rybník</ListItem>
              </div>
            )}
            returnFocus
            autoFocus
          >
            <div
              className={css({
                marginRight: "1rem",
              })}
            >
              <Button appearance={ButtonAppearance.minimal}>
                <Icons.Menu />
              </Button>
            </div>
          </StatefulPopover>
        </div>
      </StyledNavigationList>
    </HeaderNavigation>
  );
}
