import { FastField, FastFieldProps } from "formik";
import React from "react";
import InputField, { InputFieldProps } from "./input-field";

type InputFieldFormProps = { name: string; numeric?: boolean } & Omit<
  InputFieldProps,
  "onChange" | "onBlur" | "value" | "name" | "error"
>;

export default function InputFieldForm(props: InputFieldFormProps) {
  const { name, numeric, ...input } = props;

  return (
    <FastField name={name}>
      {({ field, form, meta }: FastFieldProps<string | number | undefined>) => (
        <InputField
          name={name}
          value={field.value}
          onChange={(e: any) => {
            form.setFieldValue(name, e.target.value);
          }}
          onBlur={(e) => {
            if (numeric) {
              const numberValue = parseFloat(e.target.value);
              form.setFieldValue(name, numberValue || "");
              e.target.value = (numberValue || "").toString();
            }
            field.onBlur(e);
          }}
          error={(meta.touched && meta.error) ?? meta.error}
          {...input}
        />
      )}
    </FastField>
  );
}
