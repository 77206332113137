import { gql, useMutation } from "@apollo/client";
import { Form, Formik, FormikHelpers } from "formik";
import React from "react";
import { useStyletron } from "styletron-react";
import ButtonForm from "../../form/button-form";
import { PondInput } from "../../models";
import PondForm from "../form";

const CREATE_POND = gql`
  mutation createPond($pondInput: PondInput!) {
    createPond(pondInput: $pondInput) {
      _id
    }
  }
`;

export default function PondScreenCreate() {
  const [createPond] = useMutation(CREATE_POND);
  const [css] = useStyletron();

  const onSubmit = async (values: any, formikHelpers: FormikHelpers<any>) => {
    const input: PondInput = {
      bastiaId: values.bastiaId,
      name: values.name,
      code: values.code,
      cages: values.cages,
      waterArea: values.waterArea,
      landArea: values.landArea,
    };

    try {
      const { data } = await createPond({
        variables: { pondInput: input },
      });
      window.open(`/pond/${data.createPond._id}`, "_self");
    } catch (error) {
      formikHelpers.setStatus("ERROR");
    }
  };

  return (
    <Formik<any>
      onSubmit={onSubmit as any}
      initialValues={{
        name: "",
        bastiaId: undefined,
        code: "",
        cages: false,
        waterArea: 0,
        landArea: 0,
      }}
    >
      <Form>
        <div
          className={css({
            maxWidth: "800px",
            margin: "0 auto",
          })}
        >
          <h1 className={css({ marginBottom: "1rem" })}>Přidat rybník</h1>

          <PondForm />
          <ButtonForm style={{ width: "100%", marginTop: "1rem" }}>
            Přidat
          </ButtonForm>
        </div>
      </Form>
    </Formik>
  );
}
