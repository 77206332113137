import { gql, useMutation, useQuery } from "@apollo/client";
import { Form, Formik, FormikHelpers } from "formik";
import React from "react";
import { useParams } from "react-router";
import { useStyletron } from "styletron-react";
import ButtonForm from "../../form/button-form";
import { Bastia, BastiaInput } from "../../models";
import BastiaForm from "../form";

const UPDATE_BASTIA = gql`
  mutation updateBastia($id: String!, $bastiaInput: BastiaInput!) {
    updateBastia(id: $id, bastiaInput: $bastiaInput) {
      _id
    }
  }
`;

const GET_BASTIA = gql`
  query bastia($id: String!) {
    bastia(id: $id) {
      _id
      name
      code
      branch {
        _id
      }
    }
  }
`;

export default function BastiaScreenEdit() {
  const [css] = useStyletron();
  const { id } = useParams<{ id: string }>();
  const [updateBastia] = useMutation(UPDATE_BASTIA);
  const { error, loading, data } = useQuery<{
    bastia: Bastia;
  }>(GET_BASTIA, {
    variables: { id },
  });
  const onSubmit = async (
    values: BastiaInput,
    formikHelpers: FormikHelpers<BastiaInput>
  ) => {
    const bastiaInput: BastiaInput = {
      branchId: values.branchId,
      name: values.name,
      code: values.code,
    };

    try {
      const { data } = await updateBastia({
        variables: { id, bastiaInput },
      });

      window.open(`/bastia/${data.updateBastia._id}`, "_self");
    } catch (error) {
      formikHelpers.setStatus("ERROR");
    }
  };

  if (!data?.bastia) {
    return <div>...</div>;
  }

  const { bastia } = data;

  return (
    <Formik<BastiaInput>
      onSubmit={onSubmit}
      initialValues={{
        name: bastia.name,
        branchId: bastia.branch._id,
        code: bastia.code,
      }}
    >
      <Form>
        <div
          className={css({
            maxWidth: "800px",
            margin: "0 auto",
          })}
        >
          <h1 className={css({ marginBottom: "1rem" })}>Aktualizovat baštu</h1>

          <BastiaForm />
          <ButtonForm style={{ width: "100%" }}>Aktualizovat</ButtonForm>
        </div>
      </Form>
    </Formik>
  );
}
