import { gql, useQuery } from '@apollo/client'
import { NavButton } from '@werein/components'
import React from 'react'
import * as Icons from 'react-feather'
import { useParams } from 'react-router'
import { Link } from 'react-router-dom'
import { useStyletron } from 'styletron-react'
import { useUser } from '../../app/components/layout'
import OverviewDatePicker from '../../components/overview-date-picker'
import { Bastia } from '../../models'
import PondOverview from '../../pond/overview'
import { margin } from '../../utils/css'

const GET_BASTIA = gql`
  query bastia($id: String!, $date: String) {
    bastia(id: $id) {
      _id
      name
      waterArea
      landArea
      noPonds
      noCages
      branch {
        _id
        company {
          _id
        }
      }
      overview(date: $date) {
        weight
        category
        amount
        plusAmount
        minusAmount
        plusWeight
        minusWeight
        estimate {
          weight
          amount
        }
      }
      ponds {
        _id
        name
        cycle {
          overview(date: $date) {
            weight
            category
            amount
            plusAmount
            minusAmount
            plusWeight
            minusWeight
            estimate {
              weight
              amount
            }
          }
        }
      }
    }
  }
`

export default function BastiaScreenIndex() {
  const [css] = useStyletron()
  const { id } = useParams<{ id: string }>()

  const { error, loading, data, refetch } = useQuery<{
    bastia: Bastia
  }>(GET_BASTIA, {
    variables: { id },
  })
  const { can } = useUser()

  if (error) {
    return <>Error</>
  }

  // if (!data) {
  //   return <>No results found</>;
  // }

  const highlightedCss = css({
    fontSize: '2rem',
  })

  return (
    <div>
      <div
        className={css({
          display: 'flex',
          justifyContent: 'space-between',
          ...margin('1rem 0'),
        })}
      >
        <h1>{data?.bastia.name}</h1>
        <div
          className={css({
            display: 'grid',
            gridTemplateColumns: 'repeat(1, 1fr)',
            gap: '1rem',
          })}
        >
          <NavButton disabled={!can({ bastia: data?.bastia })} to={`/bastia/${id}/edit`}>
            <Icons.Edit />
          </NavButton>
        </div>
      </div>

      <div
        className={css({
          marginTop: '1rem',
        })}
      >
        <OverviewDatePicker cb={(date) => refetch({ id, date })} />
      </div>
      {data && (
        <>
          <div
            className={css({
              display: 'flex',
              justifyContent: 'flex-end',
              ...margin('1rem 0'),
            })}
          >
            <div
              className={css({
                display: 'grid',
                gap: '1rem',
                '@media (min-width: 720px)': {
                  gridTemplateColumns: 'repeat(4, 1fr)',
                },
              })}
            >
              <div>
                <div className={highlightedCss}>{data.bastia.noPonds}</div>
                <div>Počet rybníků</div>
              </div>
              <div>
                <div className={highlightedCss}>{data.bastia.noCages}</div>
                <div>Počet sádek</div>
              </div>
              <div>
                <div className={highlightedCss}>
                  {data.bastia.landArea.toFixed(4)}
                  <small
                    className={css({
                      fontSize: '1.5rem',
                    })}
                  >
                    ha
                  </small>
                </div>
                <div>Katastrální výměra</div>
              </div>
              <div>
                <div className={highlightedCss}>
                  {data.bastia.waterArea.toFixed(4)}
                  <small
                    className={css({
                      fontSize: '1.5rem',
                    })}
                  >
                    ha
                  </small>
                </div>
                <div>Vodní plocha</div>
              </div>
            </div>
          </div>
          <PondOverview items={data.bastia.overview} />

          <div>
            {data.bastia.ponds.map((pond) => (
              <div
                className={css({
                  marginTop: '1rem',
                })}
              >
                <div>
                  <Link to={`/pond/${pond._id}`}>
                    <h2>{pond.name}</h2>
                  </Link>
                </div>
                <PondOverview items={pond.cycle.overview} />
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  )
}
