import { useField } from "formik";
import React, { PropsWithChildren } from "react";
import { CheckboxProps } from "../components/checkbox";
import CheckboxField from "./checkbox-field";
import { FieldProps } from "./field";

export type CheckboxFieldFormProps = { name: string } & Omit<
  CheckboxProps,
  "onChange" | "value"
> &
  Omit<FieldProps, "error">;

export default function CheckboxFieldForm(
  props: PropsWithChildren<CheckboxFieldFormProps>
) {
  const { name, label, caption, autoHide, children, ...input } = props;
  const [{ value }, meta, { setValue }] = useField(name);

  return (
    <CheckboxField
      label={label}
      caption={caption}
      autoHide={autoHide}
      name={name}
      error={meta.touched && meta.error}
      onChange={setValue}
      value={value}
    >
      {children}
    </CheckboxField>
  );
}
