import { useFormikContext } from "formik";
import React from "react";
import BranchSelectFieldForm from "../app/form/branch-select-field-form";
import InputFieldForm from "../form/input-field-form";

export default function BastiaForm() {
  const { values } = useFormikContext();

  return (
    <div>
      <BranchSelectFieldForm label="Středisko" name="branchId" />
      <InputFieldForm label="Název" name="name" />
      <InputFieldForm label="Označení" name="code" />
    </div>
  );
}
