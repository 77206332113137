import React, { PropsWithChildren, useState } from "react";
import DatePickerField from "../form/date-picker-field";

export default function OverviewDatePicker(
  props: PropsWithChildren<{
    cb: (d: Date) => void;
  }>
) {
  const [date, setDate] = useState<Date | undefined>(undefined);

  return (
    <DatePickerField
      clearable
      label="Zobrazit přehled ke dni"
      value={date}
      onChange={(d: any) => {
        setDate(d);
        props.cb(d);
      }}
    />
  );
}
