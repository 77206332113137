import React, { PropsWithChildren } from 'react'
import { PondOverview as PondOverviewType } from '../models'
import PondOverviewExcel from './overview-excel'

export default function PondOverview(
  props: PropsWithChildren<{
    items: PondOverviewType[]
  }>
) {
  return <PondOverviewExcel items={props.items} />
}
