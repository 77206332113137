import { gql, useMutation, useQuery } from "@apollo/client";
import { Form, Formik, FormikHelpers } from "formik";
import React from "react";
import { useParams } from "react-router";
import { useStyletron } from "styletron-react";
import ButtonForm from "../../form/button-form";
import { Branch, BranchInput } from "../../models";
import BranchForm from "../form";

const GET_BRANCH = gql`
  query branch($id: String!) {
    branch(id: $id) {
      _id
      name
      landArea
      waterArea
    }
  }
`;

const UPDATE_BRANCH = gql`
  mutation updateBranch($id: String!, $branchInput: BranchInput!) {
    updateBranch(id: $id, branchInput: $branchInput) {
      _id
    }
  }
`;

export default function BranchScreenEdit() {
  const [css] = useStyletron();
  const { id } = useParams<{ id: string }>();
  const [updateBranch] = useMutation(UPDATE_BRANCH);
  const { error, loading, data } = useQuery<{
    branch: Branch;
  }>(GET_BRANCH, {
    variables: { id },
  });

  const onSubmit = async (
    values: BranchInput,
    formikHelpers: FormikHelpers<BranchInput>
  ) => {
    const branchInput: BranchInput = {
      name: values.name,
      code: values.code,
    };

    try {
      const { data } = await updateBranch({
        variables: { id, branchInput },
      });

      window.open(`/branch/${data.updateBranch._id}`, "_self");
    } catch (error) {
      formikHelpers.setStatus("ERROR");
    }
  };

  if (!data?.branch) {
    return <div>...</div>;
  }

  const { branch } = data;

  return (
    <Formik<BranchInput>
      onSubmit={onSubmit}
      initialValues={{ name: branch.name, code: branch.code || "" }}
    >
      <Form>
        <div
          className={css({
            maxWidth: "800px",
            margin: "0 auto",
          })}
        >
          <h1 className={css({ marginBottom: "1rem" })}>
            Aktualizovat středisko
          </h1>

          <BranchForm />
          <ButtonForm style={{ width: "100%" }}>Aktualizovat</ButtonForm>
        </div>
      </Form>
    </Formik>
  );
}
