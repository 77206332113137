import { gql, useQuery } from '@apollo/client'
import { ButtonAppearance, NavButton } from '@werein/components'
import { StatefulMenu } from 'baseui/menu'
import { StatefulPopover } from 'baseui/popover'
import { parseISO } from 'date-fns'
import { sort } from 'ramda'
import React, { useEffect } from 'react'
import * as Icons from 'react-feather'
import { useHistory, useParams } from 'react-router'
import { useStyletron } from 'styletron-react'
import { Pond } from '../../models'
import { margin } from '../../utils/css'
import { sortByDate } from '../../utils/date'
import PondOverview from '../overview'

const GET_POND = gql`
  query pond($id: String!, $cycle: String!) {
    pond(id: $id) {
      _id
      name
      landArea
      code
      waterArea
      cycles {
        _id
        closedAt
        name
      }
      historicCycle(id: $cycle) {
        _id
        name
        closedAt
        entries {
          date
          weight
          amount
          category
          massUnit
          note
          pond {
            name
            _id
          }
        }
        overview {
          category
          weight
          amount
          plusAmount
          minusAmount
          plusWeight
          minusWeight
          estimate {
            weight
            amount
          }
        }
      }
    }
  }
`

export default function PondScreenCycle() {
  const [css] = useStyletron()
  const { push } = useHistory()
  const { id, cycle } = useParams<{ id: string; cycle: string }>()
  const { error, loading, data, refetch } = useQuery<{
    pond: Pond
  }>(GET_POND, {
    variables: { id, cycle },
  })

  useEffect(() => {
    refetch({ id })
  }, [id])

  if (error) {
    return <>Error</>
  }

  if (loading) {
    return <>...</>
  }

  if (!data) {
    return <>No results found</>
  }

  const highlightedCss = css({
    fontSize: '2rem',
  })

  const sorted = sort(sortByDate, data.pond.historicCycle.entries)
  const historicCycles = data.pond.cycles.filter((c) => c.closedAt)

  return (
    <div>
      <div
        className={css({
          display: 'grid',
          gridTemplateColumns: 'repeat(2, 1fr)',
          gap: '1rem',
        })}
      >
        <NavButton to={`/pond/${id}`}>
          <Icons.ArrowLeft />
        </NavButton>

        {historicCycles.length > 0 ? (
          <StatefulPopover
            content={() => (
              <StatefulMenu
                onItemSelect={({ item }) => push(`/pond/${id}/${item.id}`)}
                items={historicCycles.map((i) => ({
                  label: i.name,
                  id: i._id,
                }))}
              />
            )}
            returnFocus
            autoFocus
          >
            <div
              className={css({
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                background: 'rgb(246, 246, 246)',
                borderRadius: '2rem',
                cursor: 'pointer',
              })}
            >
              <Icons.Archive />
            </div>
          </StatefulPopover>
        ) : (
          <div />
        )}
      </div>
      <div
        className={css({
          display: 'flex',
          justifyContent: 'space-between',
          ...margin('1rem 0'),
        })}
      >
        <div>
          <h1>{data.pond.name}</h1>
          <h2>{data.pond.historicCycle.name}</h2>
        </div>
        <div
          className={css({
            display: 'grid',
            gap: '1rem',
            '@media (min-width: 720px)': {
              gridTemplateColumns: 'repeat(2, 1fr)',
            },
          })}
        >
          <div>
            <div className={highlightedCss}>
              {data.pond.landArea}
              <small
                className={css({
                  fontSize: '1.5rem',
                })}
              >
                ha
              </small>
            </div>
            <div>Katastrální výměra</div>
          </div>
          <div>
            <div className={highlightedCss}>
              {data.pond.waterArea.toFixed(4)}
              <small
                className={css({
                  fontSize: '1.5rem',
                })}
              >
                ha
              </small>
            </div>
            <div>Vodní plocha</div>
          </div>
        </div>
      </div>
      <div>
        <PondOverview items={data.pond.historicCycle.overview} />
      </div>
      <div
        role="grid"
        className={css({
          ...margin('1rem 0'),
          display: 'grid',
          gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr 1fr ',
        })}
      >
        <div
          role="row"
          className={css({
            display: 'contents',
            '@media (max-width: 720px)': {
              display: 'none',
            },
          })}
        >
          <div
            className={css({
              fontWeight: 'bold',
              padding: '0.5rem 0',
              borderBottom: 'solid 1px var(--black-20)',
              lineHeight: '2rem',
            })}
          >
            Datum
          </div>
          <div
            className={css({
              fontWeight: 'bold',
              padding: '0.5rem 0',
              borderBottom: 'solid 1px var(--black-20)',
              lineHeight: '2rem',
            })}
          >
            Kategorie
          </div>
          <div
            className={css({
              fontWeight: 'bold',
              padding: '0.5rem 0',
              borderBottom: 'solid 1px var(--black-20)',
              lineHeight: '2rem',
            })}
          >
            Počet ks
          </div>
          <div
            className={css({
              fontWeight: 'bold',
              padding: '0.5rem 0',
              borderBottom: 'solid 1px var(--black-20)',
              lineHeight: '2rem',
            })}
          >
            Hmotnost
          </div>
          <div
            className={css({
              fontWeight: 'bold',
              padding: '0.5rem 0',
              borderBottom: 'solid 1px var(--black-20)',
              lineHeight: '2rem',
            })}
          >
            Hmotnost/kus
          </div>
          <div
            className={css({
              fontWeight: 'bold',
              padding: '0.5rem 0',
              borderBottom: 'solid 1px var(--black-20)',
              lineHeight: '2rem',
            })}
          >
            Zdroj
          </div>
          <div
            className={css({
              fontWeight: 'bold',
              padding: '0.5rem 0',
              borderBottom: 'solid 1px var(--black-20)',
              lineHeight: '2rem',
            })}
          >
            Poznámka
          </div>
        </div>
        {sorted.map((e) => {
          return (
            <div
              role="row"
              className={css({
                display: 'contents',
              })}
            >
              <div
                className={css({
                  padding: '0.5rem 0',
                  borderBottom: 'solid 1px var(--black-20)',
                  lineHeight: '2rem',
                })}
              >
                {parseISO(e.date).toLocaleDateString()}
              </div>
              <div
                className={css({
                  padding: '0.5rem 0',
                  borderBottom: 'solid 1px var(--black-20)',
                  lineHeight: '2rem',
                })}
              >
                {e.category}
              </div>
              <div
                className={css({
                  padding: '0.5rem 0',
                  borderBottom: 'solid 1px var(--black-20)',
                  lineHeight: '2rem',
                })}
              >
                {e.amount} ks
              </div>
              <div
                className={css({
                  padding: '0.5rem 0',
                  borderBottom: 'solid 1px var(--black-20)',
                  lineHeight: '2rem',
                })}
              >
                {e.weight} kg
              </div>
              <div
                className={css({
                  padding: '0.5rem 0',
                  borderBottom: 'solid 1px var(--black-20)',
                  lineHeight: '2rem',
                })}
              >
                {e.massUnit.toFixed(3)} kg/ks
              </div>
              <div
                className={css({
                  padding: '0.5rem 0',
                  borderBottom: 'solid 1px var(--black-20)',
                  lineHeight: '2rem',
                })}
              >
                {e.pond && (
                  <NavButton appearance={ButtonAppearance.secondary} style={{ width: '100%' }} to={`/pond/${e.pond._id}`}>
                    {e.pond.name}
                  </NavButton>
                )}
              </div>
              <div
                className={css({
                  padding: '0.5rem',
                  borderBottom: 'solid 1px var(--black-20)',
                  lineHeight: '2rem',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  cursor: 'pointer',
                })}
              >
                <StatefulPopover
                  content={() => (
                    <div
                      className={css({
                        padding: '20px',
                      })}
                    >
                      {e.note}
                    </div>
                  )}
                  returnFocus
                  autoFocus
                >
                  {e.note}
                </StatefulPopover>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}
