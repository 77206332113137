import { FastField, FastFieldProps } from "formik";
import React from "react";
import { FieldProps } from "../app/form/field";
import Field from "../form/field";
import CategorySelect, { CategorySelectProps } from "./category-select";

type Props = { name: string } & Omit<FieldProps, "error"> &
  Omit<CategorySelectProps, "onChange" | "onBlur" | "value" | "name">;

export default function CategorySelectFieldForm(props: Props) {
  const { name, label, caption, autoHide, ...input } = props;

  return (
    <FastField name={name}>
      {({ field, form, meta }: FastFieldProps<string | undefined>) => (
        <Field
          error={meta.touched && meta.error}
          label={label}
          caption={caption}
          autoHide={autoHide}
        >
          <CategorySelect
            value={field.value}
            onChange={(value) => form.setFieldValue(name, value)}
            // error={meta.touched && !!meta.error}
            {...input}
          />
        </Field>
      )}
    </FastField>
  );
}
