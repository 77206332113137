import React, { PropsWithChildren } from "react";
import Checkbox, { CheckboxProps } from "../components/checkbox";
import Field, { FieldProps } from "./field";

export type CheckboxFieldProps = { name: string } & FieldProps &
  Omit<CheckboxProps, "error">;

export default function CheckboxField(
  props: PropsWithChildren<CheckboxFieldProps>
) {
  const { name, label, caption, autoHide, error, ...input } = props;

  return (
    <Field error={error} label={label} caption={caption} autoHide={autoHide}>
      <Checkbox name={name} error={!!error} {...input} />
    </Field>
  );
}
