import { Input as BaseInput } from "baseui/input";
import React, { ReactNode } from "react";
import { StyleObject } from "styletron-react";

export interface InputProps {
  name?: string;
  value?: string | number;
  onChange?: (event: React.FormEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
  placeholder?: string;
  disabled?: boolean;
  error?: boolean;
  prefix?: ReactNode;
  suffix?: ReactNode;
  style?: StyleObject;
}

export default function Input(props: InputProps) {
  const {
    name,
    value,
    onChange,
    placeholder,
    disabled,
    onBlur,
    error,
    style = {},
  } = props;

  return (
    <BaseInput
      name={name}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      disabled={disabled}
      onBlur={onBlur}
      error={error}
      startEnhancer={props.prefix}
      endEnhancer={props.suffix}
      onFocus={props.onFocus}
      overrides={{
        Input: {
          style: () => ({
            ...style,
          }),
        },
        // EndEnhancer: {
        //   style: ({ $isFocused }) => {
        //     return {
        //       backgroundColor: "white",
        //       borderTop: $isFocused
        //         ? "solid 2px rgb(0, 0, 0)"
        //         : "solid 2px rgb(238, 238, 238)",
        //       borderRight: $isFocused
        //         ? "solid 2px rgb(0, 0, 0)"
        //         : "solid 2px rgb(238, 238, 238)",
        //       borderBottom: $isFocused
        //         ? "solid 2px rgb(0, 0, 0)"
        //         : "solid 2px rgb(238, 238, 238)",
        //       borderLeft: "none",
        //       color: "black",
        //     };
        //   },
        // },
      }}
    />
  );
}
