import { gql, useQuery } from '@apollo/client'
import { useStyletron } from 'baseui'
import { TriangleRight } from 'baseui/icon'
import { ARTWORK_SIZES, ListItem, ListItemLabel } from 'baseui/list'
import * as R from 'ramda'
import React, { Fragment, useState } from 'react'
import * as Icons from 'react-feather'
import { useHistory, useLocation } from 'react-router'
import InputField from '../../form/input-field'
import { Bastia, Branch, Company, Pond } from '../../models'

const sortByName = R.sortBy(R.compose(R.toLower, R.prop('name')))

export const CURRENT_USER = gql`
  query currentUserQuery {
    currentUser {
      company {
        branches {
          _id
          name
          code
          bastias {
            name
            _id
            code
            ponds {
              bastia {
                _id
              }
              cages
              _id
              code
              name
            }
          }
        }
      }
    }
  }
`

export default function List() {
  const [isOpen, setOpen] = useState<string | undefined>(undefined)
  const [css] = useStyletron()
  const { push } = useHistory()
  const { pathname } = useLocation()
  const [search, setSearch] = useState('')
  const [locating, setLocating] = useState(false)
  const { loading, error, data } = useQuery<{
    currentUser: { company: Company }
  }>(CURRENT_USER)

  if (error) {
    return <div>Error!</div>
  }
  if (loading) {
    return <>...</>
  }

  if (!data) {
    return <div>No results found</div>
  }

  const isActive = (id: string) => pathname.includes(id)

  const currentStyle = (id: string) => (isActive(id) ? { background: 'var(--dark-blue)', color: 'white' } : {})

  const filterPonds = (p: Pond) => p.name.toLowerCase().includes(search.toLowerCase()) || p.code.toLowerCase().includes(search.toLowerCase())

  const filterBastias = (b: Bastia) =>
    b.name.toLowerCase().includes(search.toLowerCase()) || b.code.toLowerCase().includes(search.toLowerCase()) || b.ponds.some(filterPonds)

  const filterBranches = (b: Branch) =>
    b.name.toLowerCase().includes(search.toLowerCase()) || b.code.toLowerCase().includes(search.toLowerCase()) || b.bastias.some(filterBastias)

  return (
    <ul
      className={css({
        overflow: 'scroll',
        width: '100%',
        paddingLeft: 0,
        paddingRight: 0,
        maxHeight: '40vh',
        '@media (min-width: 720px)': {
          borderRight: 'solid 1px rgb(203, 203, 203)',
          maxHeight: 'calc(100vh - 61px)',
        },
        '@media print': {
          display: 'none',
        },
      })}
    >
      <div>
        <InputField
          placeholder="Zadejte název nebo kód "
          name="search"
          value={search}
          onChange={(e: any) => setSearch(e.target.value)}
          autoHide
          suffix={search ? <Icons.X onClick={() => setSearch('')} /> : <Icons.Search />}
        />
      </div>
      {data.currentUser.company.branches.filter(filterBranches).map((b) => (
        <>
          <div onClick={() => push(`/branch/${b._id}`)} className={css({ cursor: 'pointer' })}>
            <ListItem
              overrides={{
                Root: {
                  style: {
                    background: 'var(--black-80)',
                    ...currentStyle(b._id),
                  },
                },
              }}
            >
              <ListItemLabel sublist>
                <div className={css({ color: 'white', fontWeight: 'bold' })}>{b.name}</div>
              </ListItemLabel>
            </ListItem>
          </div>
          {b.bastias.filter(filterBastias).map((bastia) => (
            <>
              <div
                onClick={() => {
                  setOpen(isOpen === bastia._id ? undefined : bastia._id)
                  push(`/bastia/${bastia._id}`)
                }}
                className={css({ cursor: 'pointer' })}
              >
                <ListItem
                  sublist
                  endEnhancer={() =>
                    isOpen === bastia._id ? (
                      <Icons.ArrowDown />
                    ) : (
                      <div
                        className={css({
                          background: 'white',
                          borderRadius: '100%',
                          color: 'black',
                          width: '1.5rem',
                          height: '1.5rem',
                          justifyContent: 'center',
                          alignItems: 'center',
                          display: 'flex',
                        })}
                      >
                        {bastia.ponds.filter((p) => !p.cages).length}
                      </div>
                    )
                  }
                  overrides={{
                    Root: {
                      style: {
                        ...currentStyle(bastia._id),
                      },
                    },
                  }}
                >
                  <ListItemLabel sublist>
                    <div
                      className={css({
                        color: isActive(bastia._id) ? 'white' : 'inherit',
                      })}
                    >
                      {bastia.name}
                    </div>
                  </ListItemLabel>
                </ListItem>
              </div>
              {sortByName(bastia.ponds)
                .sort((a, b) => Intl.Collator('cs').compare(a.name.toLocaleLowerCase(), b.name.toLocaleLowerCase()))
                .filter(filterPonds)
                .map((pond) => {
                  if ((isOpen === pond.bastia._id && !pond.cages) || (isOpen !== pond.bastia._id && pond.cages) || search) {
                    return (
                      <div onClick={() => push(`/pond/${pond._id}`)} className={css({ cursor: 'pointer' })}>
                        <ListItem
                          sublist
                          artwork={TriangleRight}
                          artworkSize={ARTWORK_SIZES.SMALL}
                          overrides={{
                            Root: {
                              style: {
                                ...currentStyle(pond._id),
                                // ...(pond.cycle.entries.length === 0 ? { backgroundColor: '#ff4c30' } : {}),
                              },
                            },
                            Content: {
                              style: {
                                height: '40px',
                              },
                            },
                          }}
                        >
                          <ListItemLabel>
                            <div
                              className={css({
                                color: isActive(pond._id) ? 'white' : 'inherit',
                              })}
                            >
                              {pond.name}
                            </div>
                          </ListItemLabel>
                        </ListItem>
                      </div>
                    )
                  } else return <Fragment />
                })}
            </>
          ))}
        </>
      ))}
    </ul>
  )
}
