import { FormControl } from "baseui/form-control";
import React, { PropsWithChildren, ReactNode } from "react";

export interface FieldProps {
  label?: string | ReactNode;
  caption?: string | ReactNode;
  error?: string | ReactNode;
  autoHide?: boolean;
}

function Field(props: PropsWithChildren<FieldProps>) {
  return (
    <FormControl
      label={props.label ? () => props.label : undefined}
      caption={() =>
        props.caption || (props.autoHide ? undefined : <div>&nbsp;</div>)
      }
      error={props.error}
      overrides={{
        ControlContainer: {
          style: {
            marginTop: 0,
            marginRight: 0,
            marginBottom: 0,
            marginLeft: 0,
          },
        },
        Caption: {
          style: {
            marginTop: "0.2rem",
            marginRight: 0,
            marginBottom: 0,
            marginLeft: 0,
          },
        },
        Label: {
          style: {
            marginTop: "0.5rem",
            marginRight: 0,
            marginBottom: "0.5rem",
            marginLeft: 0,
          },
        },
      }}
    >
      {props.children}
    </FormControl>
  );
}

export default Field;
