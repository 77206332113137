import { useAuth0 } from "@auth0/auth0-react";
import { Button, ButtonAppearance } from "@werein/components";
import React, { PropsWithChildren, useEffect } from "react";
import { Route, Switch, useLocation } from "react-router";
import { useStyletron } from "styletron-react";
import "./app.css";
import Layout from "./app/components/layout";
import logo from "./app/fishery.png";
import BastiaScreenIndex from "./bastia/screens";
import BastiaScreenCreate from "./bastia/screens/create";
import BastiaScreenEdit from "./bastia/screens/edit";
import BranchScreenIndex from "./branch/screens";
import BranchScreenCreate from "./branch/screens/create";
import BranchScreenEdit from "./branch/screens/edit";
import HomeScreen from "./home/screens";
import PondScreenIndex from "./pond/screens";
import PondScreenCreate from "./pond/screens/create";
import PondScreenCycle from "./pond/screens/cycle";
import PondScreenEdit from "./pond/screens/edit";
import PondScreenEstimate from "./pond/screens/estimate";

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App(props: PropsWithChildren<{}>) {
  const { isAuthenticated, loginWithRedirect } = useAuth0();
  const [css] = useStyletron();

  if (isAuthenticated) {
    return (
      <>
        <Layout>
          {/* <ScrollToTop /> */}

          <Switch>
            <Route exact path="/" component={HomeScreen} />
            <Route
              exact
              path="/branches/create"
              component={BranchScreenCreate}
            />
            <Route exact path="/branch/:id" component={BranchScreenIndex} />
            <Route exact path="/branch/:id/edit" component={BranchScreenEdit} />
            <Route
              exact
              path="/bastias/create"
              component={BastiaScreenCreate}
            />
            <Route exact path="/bastia/:id">
              <BastiaScreenIndex />
            </Route>
            <Route exact path="/bastia/:id/edit" component={BastiaScreenEdit} />
            <Route exact path="/ponds/create" component={PondScreenCreate} />
            <Route exact path="/pond/:id" component={PondScreenIndex} />
            <Route exact path="/pond/:id/edit" component={PondScreenEdit} />
            <Route
              exact
              path="/pond/:id/estimate"
              component={PondScreenEstimate}
            />
            <Route exact path="/pond/:id/:cycle" component={PondScreenCycle} />
          </Switch>
        </Layout>
      </>
    );
  }

  return (
    <div
      className={css({
        margin: "8rem auto",
        maxWidth: "300px",
      })}
    >
      <header>
        <img
          className={css({
            width: "100%",
          })}
          src={logo}
        />
        <h1>Fishery.cz</h1>
      </header>
      <div
        className={css({
          marginTop: "3rem",
        })}
      >
        <Button
          appearance={ButtonAppearance.primary}
          onClick={loginWithRedirect}
          style={{ width: "100%" }}
        >
          Přihlásit se
        </Button>
      </div>
    </div>
  );
}

export default App;
