import React from "react";
import Field, { FieldProps } from "./field";
import Input, { InputProps } from "./input";

export type InputFieldProps = { name: string } & FieldProps &
  Omit<InputProps, "error">;

export default function InputField(props: InputFieldProps) {
  const {
    autoHide,
    classNameField,
    name,
    label,
    caption,
    error,
    value,
    onChange,
    onBlur,
    ...input
  } = props;

  return (
    <Field
      classNameField={classNameField}
      autoHide={autoHide}
      error={error}
      label={label}
      caption={error || caption}
    >
      <Input
        name={name}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        error={!!error}
        {...input}
      />
    </Field>
  );
}
