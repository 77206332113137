import { ApolloQueryResult, gql, useMutation } from "@apollo/client";
import { Button, ButtonAppearance } from "@werein/components";
import React from "react";
import * as Icons from "react-feather";

interface PondCloseCycleProps {
  id: string;
  reload: () => Promise<ApolloQueryResult<any>>;
}

const CLOSE_POND_CYCLE = gql`
  mutation closePondCycle($id: String!, $name: String!) {
    closePondCycle(id: $id, name: $name) {
      _id
    }
  }
`;

export default function PondCloseCycle(props: PondCloseCycleProps) {
  const { id, reload } = props;
  const [closePondCycle] = useMutation(CLOSE_POND_CYCLE);

  const onClick = async () => {
    const name = window.prompt(
      "Jste si jisti, že chcete udělat uzávěrku? Pokud ano, pojmenujte ji"
    );
    if (name) {
      try {
        await closePondCycle({
          variables: { id, name },
        });
        await reload();
      } catch (e) {
        console.log(e);
      }
    }
  };

  return (
    <>
      <Button
        appearance={ButtonAppearance.secondary}
        icon={<Icons.Lock />}
        onClick={onClick}
      >
        Uzavřít
      </Button>
    </>
  );
}
