import { gql, useMutation } from "@apollo/client";
import { Form, Formik, FormikHelpers } from "formik";
import React from "react";
import { useStyletron } from "styletron-react";
import ButtonForm from "../../form/button-form";
import { BranchInput } from "../../models";
import BranchForm from "../form";

const CREATE_BRANCH = gql`
  mutation createBranch($branchInput: BranchInput!) {
    createBranch(branchInput: $branchInput) {
      _id
    }
  }
`;

export default function BranchScreenCreate() {
  const [createBranch] = useMutation(CREATE_BRANCH);
  const [css] = useStyletron();

  const onSubmit = async (
    values: BranchInput,
    formikHelpers: FormikHelpers<BranchInput>
  ) => {
    const input: BranchInput = {
      name: values.name,
      code: values.code,
    };

    try {
      const { data } = await createBranch({
        variables: { branchInput: input },
      });

      window.open(`/branch/${data.createBranch._id}`, "_self");
    } catch (error) {
      formikHelpers.setStatus("ERROR");
    }
  };

  return (
    <Formik<BranchInput>
      onSubmit={onSubmit}
      initialValues={{ name: "", code: "" }}
    >
      <Form>
        <div
          className={css({
            maxWidth: "800px",
            margin: "0 auto",
          })}
        >
          <h1 className={css({ marginBottom: "1rem" })}>Přidat středisko</h1>

          <BranchForm />
          <ButtonForm style={{ width: "100%" }}>Přidat</ButtonForm>
        </div>
      </Form>
    </Formik>
  );
}
