import { gql, useQuery } from '@apollo/client'
import { NavButton } from '@werein/components'
import React from 'react'
import * as Icons from 'react-feather'
import { useParams } from 'react-router'
import { Link } from 'react-router-dom'
import { useStyletron } from 'styletron-react'
import { useUser } from '../../app/components/layout'
import OverviewDatePicker from '../../components/overview-date-picker'
import { Branch } from '../../models'
import PondOverview from '../../pond/overview'
import { margin } from '../../utils/css'

const GET_BRANCH = gql`
  query branch($id: String!, $date: String) {
    branch(id: $id) {
      _id
      name
      landArea
      waterArea
      noBastias
      noPonds
      noCages
      overview(date: $date) {
        category
        weight
        amount
        plusAmount
        minusAmount
        plusWeight
        minusWeight
        estimate {
          weight
          amount
        }
      }
      company {
        _id
      }
      bastias {
        _id
        name
        overview(date: $date) {
          category
          weight
          amount
          plusAmount
          minusAmount
          plusWeight
          minusWeight
          estimate {
            weight
            amount
          }
        }
      }
    }
  }
`

export default function BranchScreenIndex() {
  const [css] = useStyletron()
  const { id } = useParams<{ id: string }>()
  const { error, loading, data, refetch } = useQuery<{
    branch: Branch
  }>(GET_BRANCH, {
    variables: { id },
  })
  const { can } = useUser()

  if (error) {
    return <>Error</>
  }

  const highlightedCss = css({
    fontSize: '2rem',
  })

  return (
    <div>
      <div
        className={css({
          display: 'flex',
          justifyContent: 'space-between',
          ...margin('1rem 0'),
        })}
      >
        <h1>{data?.branch.name}</h1>
        <div
          className={css({
            display: 'grid',
            gridTemplateColumns: 'repeat(1, 1fr)',
            gap: '1rem',
          })}
        >
          <NavButton disabled={!can({ branch: data?.branch })} to={`/branch/${id}/edit`}>
            <Icons.Edit />
          </NavButton>
        </div>
      </div>
      <div
        className={css({
          display: 'flex',
          justifyContent: 'space-end',
          ...margin('1rem 0'),
        })}
      >
        <div
          className={css({
            display: 'grid',
            gap: '1rem',
            '@media (min-width: 720px)': {
              gridTemplateColumns: 'repeat(5, 1fr)',
            },
          })}
        >
          <div>
            <div className={highlightedCss}>{data?.branch.noBastias}</div>
            <div>Počet bašt</div>
          </div>
          <div>
            <div className={highlightedCss}>{data?.branch.noPonds}</div>
            <div>Počet rybníků</div>
          </div>
          <div>
            <div className={highlightedCss}>{data?.branch.noCages}</div>
            <div>Počet sádek</div>
          </div>
          <div>
            <div className={highlightedCss}>
              {data?.branch.landArea.toFixed(4)}
              <small
                className={css({
                  fontSize: '1.5rem',
                })}
              >
                ha
              </small>
            </div>
            <div>Katastrální výměra</div>
          </div>
          <div>
            <div className={highlightedCss}>
              {data?.branch.waterArea.toFixed(4)}
              <small
                className={css({
                  fontSize: '1.5rem',
                })}
              >
                ha
              </small>
            </div>
            <div>Vodní plocha</div>
          </div>
        </div>
      </div>

      <div
        className={css({
          marginTop: '1rem',
        })}
      >
        <OverviewDatePicker cb={(date) => refetch({ id, date })} />
      </div>
      {data && <PondOverview items={data.branch.overview} />}
      <div>
        {data &&
          data.branch.bastias.map((bastia) => (
            <div
              className={css({
                marginTop: '1rem',
              })}
            >
              <div>
                <Link to={`/bastia/${bastia._id}`}>
                  <h2>{bastia.name}</h2>
                </Link>
              </div>
              <PondOverview items={bastia.overview} />
            </div>
          ))}
      </div>
    </div>
  )
}
