import { Category, CategoryInput } from "../models";

export const subset = (c: string) => {
  switch (c) {
    case Category.Ab:
      return [
        CategoryInput.Ab0,
        CategoryInput.Ab1,
        CategoryInput.Ab2,
        CategoryInput.Ab3,
        CategoryInput.AbT,
        CategoryInput.Abx,
      ];
    case Category.Ca:
      return [
        CategoryInput.Ca0,
        CategoryInput.Car,
        CategoryInput.Ca1,
        CategoryInput.Ca2,
        CategoryInput.Ca3,
        CategoryInput.CaT,
        CategoryInput.Cax,
      ];
    case Category.Ka:
      return [CategoryInput.Ka, CategoryInput.KaT];
    case Category.Ks:
      return [
        CategoryInput.KrS,
        CategoryInput.K0S,
        CategoryInput.K1S,
        CategoryInput.K2S,
        CategoryInput.K3S,
        CategoryInput.K4S,
        CategoryInput.Kts,
        CategoryInput.Kvs,
        CategoryInput.KxS,
      ];
    case Category.Khl:
      return [
        CategoryInput.KrHl,
        CategoryInput.K0Hl,
        CategoryInput.K1Hl,
        CategoryInput.K2Hl,
        CategoryInput.K3Hl,
        CategoryInput.K4Hl,
        CategoryInput.KtHl,
        CategoryInput.KvHl,
        CategoryInput.KxHl,
      ];
    case Category.L:
      return [
        CategoryInput.L0,
        CategoryInput.L1,
        CategoryInput.L2,
        CategoryInput.L3,
        CategoryInput.Lt,
      ];
    case Category.S:
      return [
        CategoryInput.S0,
        CategoryInput.Sr,
        CategoryInput.S1,
        CategoryInput.S2,
        CategoryInput.S3,
        CategoryInput.St,
      ];
    case Category.Su:
      return [
        CategoryInput.Su0,
        CategoryInput.Su1,
        CategoryInput.Su2,
        CategoryInput.Su3,
        CategoryInput.SuT,
      ];
    case Category.Tb:
      return [
        CategoryInput.Tb0,
        CategoryInput.Tb1,
        CategoryInput.Tb2,
        CategoryInput.Tb3,
        CategoryInput.TbT,
      ];
    case Category.Tp:
      return [
        CategoryInput.Tp0,
        CategoryInput.Tp1,
        CategoryInput.Tp2,
        CategoryInput.Tp3,
        CategoryInput.TpT,
      ];
    default:
      return [c];
  }
};
