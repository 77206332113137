import { gql, useQuery } from "@apollo/client";
import { OptgroupsT, Select, SelectProps } from "baseui/select";
import { flatten, mergeAll } from "ramda";
import React from "react";
import { Branch } from "../models";

const GET_BRANCHES = gql`
  query branches {
    branches {
      _id
      name
      bastias {
        _id
        name
        ponds {
          _id
          name
        }
      }
    }
  }
`;

export type PondSelectProps = Omit<
  SelectProps,
  "options" | "isLoading" | "value"
> & { value?: string };

export default function PondSelect(props: PondSelectProps) {
  const { error, loading, data } = useQuery<{
    branches: [Branch];
  }>(GET_BRANCHES);

  if (error) {
    return <>Error</>;
  }

  const { value, ...rest } = props;

  const optionsGrouped =
    data?.branches.map((branch) =>
      branch.bastias.map((bastia) => {
        const name = `${branch.name}: ${bastia.name}`;
        return {
          [name]: bastia.ponds.map((pond) => ({
            label: pond.name,
            id: pond._id,
          })),
        };
      })
    ) || [];

  const flattened = flatten(optionsGrouped);
  const mergedOptions = mergeAll(flattened);
  const ungruped = Object.keys(mergedOptions).map((key) => mergedOptions[key]);
  const flattenedUngrouped = flatten(ungruped);

  const options: OptgroupsT = {
    __ungrouped: [],
    ...mergedOptions,
  };

  const selectValue = flattenedUngrouped.find(
    (option) => option && option.id === value
  );

  return (
    <Select
      isLoading={loading}
      options={options}
      placeholder="Vyberte rybník"
      value={selectValue ? [selectValue] : undefined}
      {...rest}
    />
  );
}
