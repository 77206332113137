export function formatNumber(
  price?: number | string | null,
  maximumFractionDigits = 0,
  minimumFractionDigits = 0
) {
  return price?.toLocaleString("cs-CZ", {
    maximumFractionDigits,
    minimumFractionDigits,
  });
}
