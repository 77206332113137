import { gql, useQuery } from '@apollo/client'
import { ButtonAppearance, NavButton } from '@werein/components'
import { StatefulPopover } from 'baseui/popover'
import { parseISO } from 'date-fns'
import { sort } from 'ramda'
import React, { useEffect, useState } from 'react'
import * as Icons from 'react-feather'
import { useHistory, useParams } from 'react-router'
import { useStyletron } from 'styletron-react'
import { useUser } from '../../app/components/layout'
import Modal from '../../components/modal'
import { Pond, PondCycleEstimate, PondOverview as PondOverviewType } from '../../models'
import { margin } from '../../utils/css'
import { sortByDate } from '../../utils/date'
import CreateEstimateForm from '../create-estimate-form'
import EditEntryForm from '../edit-entry-form'
import PondOverview from '../overview'

const GET_POND = gql`
  query pond($id: String!, $date: String) {
    pond(id: $id) {
      _id
      name
      landArea
      code
      waterArea
      deleted
      bastia {
        branch {
          _id
          company {
            _id
          }
        }
      }
      cycles {
        _id
        name
        closedAt
        overview {
          category
          weight
          amount
        }
      }
      cycle {
        _id
        closedAt
        estimates {
          _id
          date
          weight
          amount
          category
          massUnit
          note
        }
      }
    }
    pondCycleOverview(pondId: $id, date: $date) {
      category
      weight
      amount
      plusAmount
      minusAmount
      plusWeight
      minusWeight
      estimate {
        weight
        amount
      }
    }
  }
`

export default function PondScreenEstimate() {
  const [css] = useStyletron()
  const { push } = useHistory()
  const [isEditing, setEditing] = useState<PondCycleEstimate | null>(null)
  const { id } = useParams<{ id: string; cycle?: string }>()
  const [date, setDate] = useState<Date | undefined>(undefined)
  const { can } = useUser()
  const { error, loading, data, refetch } = useQuery<{
    pond: Pond
    pondCycleOverview: PondOverviewType[]
  }>(GET_POND, {
    variables: { id, date },
  })

  useEffect(() => {
    refetch({ id })
  }, [id])

  if (error) {
    return <>Error</>
  }

  if (loading) {
    return <>...</>
  }

  if (!data) {
    return <>No results found</>
  }

  const highlightedCss = css({
    fontSize: '2rem',
  })

  const hasRight = can({ pond: data.pond })
  const sorted = sort(sortByDate, data.pond.cycle.estimates).reverse()
  return (
    <div>
      <div
        className={css({
          display: 'flex',
          justifyContent: 'space-between',
          ...margin('1rem 0'),
        })}
      >
        <div>
          <h1>{data.pond.name}</h1>
        </div>

        <div
          className={css({
            display: 'grid',
            gap: '1rem',
            '@media (min-width: 720px)': {
              gridTemplateColumns: 'repeat(2, 1fr)',
            },
          })}
        >
          <div>
            <div className={highlightedCss}>
              {data.pond.landArea.toFixed(4)}
              <small
                className={css({
                  fontSize: '1.5rem',
                })}
              >
                ha
              </small>
            </div>
            <div>Katastrální výměra</div>
          </div>
          <div>
            <div className={highlightedCss}>
              {data.pond.waterArea.toFixed(4)}
              <small
                className={css({
                  fontSize: '1.5rem',
                })}
              >
                ha
              </small>
            </div>
            <div>Vodní plocha</div>
          </div>
        </div>
      </div>

      <div
        className={css({
          display: 'grid',
          gridTemplateColumns: 'repeat(4, 1fr)',
          gap: '1rem',
          marginBottom: '1rem',
        })}
      >
        {!data.pond.deleted && hasRight && (
          <>
            <NavButton appearance={ButtonAppearance.secondary} to={`/pond/${id}`} icon={<Icons.ArrowLeft />}>
              Zpět
            </NavButton>
            <NavButton appearance={ButtonAppearance.secondary} to={`/pond/${id}/estimate`} icon={<Icons.BookOpen />} disabled>
              Odhady
            </NavButton>
          </>
        )}
      </div>

      {data.pondCycleOverview && (
        <PondOverview
          items={data.pondCycleOverview}
          // estimates={data.pond.cycle.estimates}
        />
      )}

      {!data.pond.deleted && hasRight && <CreateEstimateForm pondId={id} cycleId={data.pond.cycle._id} refetch={refetch} />}

      {data.pond.deleted && <div>Tento záznam byl archivován. Není možné provádět žádné změny</div>}

      <div
        role="grid"
        className={css({
          ...margin('1rem 0'),
          display: 'grid',
          gridTemplateColumns: '2fr 2fr 2fr 2fr 2fr 2fr 1fr',
        })}
      >
        <div
          role="row"
          className={css({
            display: 'contents',
            '@media (max-width: 720px)': {
              display: 'none',
            },
          })}
        >
          <div
            className={css({
              fontWeight: 'bold',
              padding: '0.5rem 0',
              borderBottom: 'solid 1px var(--black-20)',
              lineHeight: '2rem',
            })}
          >
            Datum
          </div>
          <div
            className={css({
              fontWeight: 'bold',
              padding: '0.5rem 0',
              borderBottom: 'solid 1px var(--black-20)',
              lineHeight: '2rem',
            })}
          >
            Kategorie
          </div>
          <div
            className={css({
              fontWeight: 'bold',
              padding: '0.5rem 0',
              borderBottom: 'solid 1px var(--black-20)',
              lineHeight: '2rem',
            })}
          >
            Počet ks
          </div>
          <div
            className={css({
              fontWeight: 'bold',
              padding: '0.5rem 0',
              borderBottom: 'solid 1px var(--black-20)',
              lineHeight: '2rem',
            })}
          >
            Hmotnost
          </div>
          <div
            className={css({
              fontWeight: 'bold',
              padding: '0.5rem 0',
              borderBottom: 'solid 1px var(--black-20)',
              lineHeight: '2rem',
            })}
          >
            Hmotnost/kus
          </div>
          <div
            className={css({
              fontWeight: 'bold',
              padding: '0.5rem 0',
              borderBottom: 'solid 1px var(--black-20)',
              lineHeight: '2rem',
            })}
          >
            Poznámka
          </div>
          <div
            className={css({
              fontWeight: 'bold',
              padding: '0.5rem 0',
              borderBottom: 'solid 1px var(--black-20)',
              lineHeight: '2rem',
            })}
          />
        </div>
        {isEditing && hasRight && (
          <Modal header="" isOpen onClose={() => setEditing(null)}>
            <EditEntryForm
              onClose={() => setEditing(null)}
              pondId={data.pond._id}
              cycleId={data.pond.cycle._id}
              id={isEditing._id}
              refetch={refetch}
              inverseCycleId={undefined}
              initialValues={{
                in: isEditing.amount > 0,
                date: parseISO(isEditing.date),
                weight: isEditing.weight > 0 ? isEditing.weight : isEditing.weight * -1,
                amount: isEditing.amount > 0 ? isEditing.amount : isEditing.amount * -1,
                massUnit: isEditing.massUnit,
                category: isEditing.category,
              }}
            />
          </Modal>
        )}
        {sorted.map((e) => {
          return (
            <div
              role="row"
              className={css({
                display: 'contents',
              })}
            >
              <div
                className={css({
                  padding: '0.5rem 0',
                  borderBottom: 'solid 1px var(--black-20)',
                  lineHeight: '2rem',
                })}
              >
                {parseISO(e.date).toLocaleDateString()}
              </div>
              <div
                className={css({
                  padding: '0.5rem 0',
                  borderBottom: 'solid 1px var(--black-20)',
                  lineHeight: '2rem',
                })}
              >
                {e.category}
              </div>
              <div
                className={css({
                  padding: '0.5rem 0',
                  borderBottom: 'solid 1px var(--black-20)',
                  lineHeight: '2rem',
                })}
              >
                {e.amount} ks
              </div>
              <div
                className={css({
                  padding: '0.5rem 0',
                  borderBottom: 'solid 1px var(--black-20)',
                  lineHeight: '2rem',
                })}
              >
                {e.weight} kg
              </div>
              <div
                className={css({
                  padding: '0.5rem 0',
                  borderBottom: 'solid 1px var(--black-20)',
                  lineHeight: '2rem',
                })}
              >
                {e.massUnit.toFixed(3)} kg/ks
              </div>
              <div
                className={css({
                  padding: '0.5rem',
                  borderBottom: 'solid 1px var(--black-20)',
                  lineHeight: '2rem',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  cursor: 'pointer',
                })}
              >
                <StatefulPopover
                  content={() => (
                    <div
                      className={css({
                        padding: '20px',
                      })}
                    >
                      {e.note}
                    </div>
                  )}
                  returnFocus
                  autoFocus
                >
                  {e.note}
                </StatefulPopover>
              </div>
              <div
                className={css({
                  padding: '0.5rem 1rem',
                  borderBottom: 'solid 1px var(--black-20)',
                  lineHeight: '2rem',
                  justifyContent: 'center',
                  display: 'grid',
                  gridTemplateColumns: '1fr 1fr',
                  gap: '0.5rem',
                })}
              >
                {/* <Button
                  icon={<Icons.Edit size="16px" />}
                  disabled={!hasRight}
                  size="compact"
                  onClick={() => setEditing(e)}
                /> */}

                {/* <Button size="medium">
                  <Icons.X size="16px" />
                </Button> */}
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}
