import React from "react";
import InputFieldForm from "../form/input-field-form";

export default function BranchForm() {
  return (
    <div>
      <InputFieldForm name="name" label="Název střediska" />
      <InputFieldForm name="code" label="Označení" />
    </div>
  );
}
