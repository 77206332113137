import { gql, useMutation, useQuery } from "@apollo/client";
import { Form, Formik, FormikHelpers } from "formik";
import React from "react";
import { useParams } from "react-router";
import { useStyletron } from "styletron-react";
import ButtonForm from "../../form/button-form";
import { Pond, PondInput } from "../../models";
import PondForm from "../form";

const GET_POND = gql`
  query pond($id: String!) {
    pond(id: $id) {
      _id
      name
      code
      cages
      landArea
      waterArea
      bastia {
        _id
      }
    }
  }
`;

const UPDATE_POND = gql`
  mutation updatePond($id: String!, $pondInput: PondInput!) {
    updatePond(id: $id, pondInput: $pondInput) {
      _id
    }
  }
`;

export default function PondScreenEdit() {
  const { id } = useParams<{ id: string }>();
  const [css] = useStyletron();
  const [updatePond] = useMutation(UPDATE_POND);
  const { error, loading, data, refetch } = useQuery<{
    pond: Pond;
  }>(GET_POND, {
    variables: { id },
  });

  const onSubmit = async (values: any, formikHelpers: FormikHelpers<any>) => {
    const pondInput: PondInput = {
      bastiaId: values.bastiaId,
      name: values.name,
      code: values.code,
      cages: values.cages,
      waterArea: values.waterArea,
      landArea: values.landArea,
    };

    try {
      const { data } = await updatePond({
        variables: { id, pondInput },
      });
      window.open(`/pond/${data.updatePond._id}`, "_self");
    } catch (error) {
      formikHelpers.setStatus("ERROR");
    }
  };

  if (!data?.pond) {
    return <div>...</div>;
  }

  const { pond } = data;

  return (
    <Formik
      onSubmit={onSubmit as any}
      initialValues={{
        name: pond.name,
        bastiaId: pond.bastia?._id,
        code: pond.code,
        cages: pond.cages,
        waterArea: pond.waterArea,
        landArea: pond.landArea,
      }}
      enableReinitialize
    >
      <Form>
        <div
          className={css({
            maxWidth: "800px",
            margin: "0 auto",
          })}
        >
          <h1 className={css({ marginBottom: "1rem" })}>Aktualizovat rybník</h1>

          <PondForm />
          <ButtonForm style={{ width: "100%", marginTop: "1rem" }}>
            Aktualizovat
          </ButtonForm>
        </div>
      </Form>
    </Formik>
  );
}
