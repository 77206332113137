import { FastField, FastFieldProps } from "formik";
import React from "react";
import BastiaSelect, { BastiaSelectProps } from "../../bastia/bastia-select";
import Field, { FieldProps } from "./field";

type Props = { name: string } & Omit<FieldProps, "error"> &
  Omit<BastiaSelectProps, "onChange" | "onBlur" | "value" | "name">;

export default function BastiaSelectFieldForm(props: Props) {
  const { name, label, caption, autoHide, ...input } = props;

  return (
    <FastField name={name}>
      {({ field, form, meta }: FastFieldProps<string | undefined>) => (
        <Field
          error={meta.touched && meta.error}
          label={label}
          caption={caption}
          autoHide={autoHide}
        >
          <BastiaSelect
            value={field.value}
            onChange={(e) => form.setFieldValue(name, e.option?.id)}
            onBlur={field.onBlur}
            error={meta.touched && !!meta.error}
            {...input}
          />
        </Field>
      )}
    </FastField>
  );
}
