import { gql, useQuery } from "@apollo/client";
import React from "react";
import ReactSelect from "react-select";
import { Branch, Category } from "../models";
import { subset } from "../utils/categories";

const GET_BRANCHES = gql`
  query branches {
    branches {
      _id
      name
      bastias {
        _id
        name
      }
    }
  }
`;

export type CategorySelectProps = {
  value?: string;
  onChange: (s?: string) => void;
};

const Categories = [
  Category.Ks,
  Category.Khl,
  Category.Ab,
  Category.L,
  Category.Ca,
  Category.S,
  Category.Su,
  Category.Tb,
  Category.Tp,
  Category.Cv,
  Category.Ka,
  Category.Or,
  Category.Pd,
  Category.Si,
  Category.Br,
  Category.Futr,
  Category.Ma,
  Category.Pe,
  Category.Je,
  Category.U,
];

const options2: any = Categories.map((category) => ({
  label: category,
  ...(subset(category).length === 1
    ? { value: category }
    : {
        options: subset(category).map((category) => ({
          label: category,
          value: category,
        })),
      }),
}));

export default function CategorySelect(props: CategorySelectProps) {
  const { error, loading, data } = useQuery<{
    branches: [Branch];
  }>(GET_BRANCHES);

  if (error) {
    return <>Error</>;
  }

  const { value, ...rest } = props;

  const selectValue = value
    ? {
        value: value,
        label: value,
      }
    : null;

  return (
    <ReactSelect
      placeholder="Vyberte kategorii"
      menuPlacement="auto"
      styles={{
        control: (b) => ({
          ...b,
          height: "48px",
        }),
      }}
      onChange={(value: any) => props.onChange(value.value)}
      options={options2}
      value={selectValue}
    />
  );
}
