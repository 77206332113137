import { gql, useMutation } from "@apollo/client";
import { Form, Formik, FormikHelpers } from "formik";
import React from "react";
import { useStyletron } from "styletron-react";
import ButtonForm from "../../form/button-form";
import { BastiaInput } from "../../models";
import BastiaForm from "../form";

const CREATE_BASTIA = gql`
  mutation createBastia($bastiaInput: BastiaInput!) {
    createBastia(bastiaInput: $bastiaInput) {
      _id
    }
  }
`;

export default function BastiaScreenCreate() {
  const [createBastia] = useMutation(CREATE_BASTIA);
  const [css] = useStyletron();

  const onSubmit = async (
    values: BastiaInput,
    formikHelpers: FormikHelpers<BastiaInput>
  ) => {
    const input: BastiaInput = {
      branchId: values.branchId,
      name: values.name,
      code: values.code,
    };

    try {
      const { data } = await createBastia({
        variables: { bastiaInput: input },
      });

      window.open(`/bastia/${data.createBastia._id}`, "_self");
    } catch (error) {
      formikHelpers.setStatus("ERROR");
    }
  };

  return (
    <Formik<BastiaInput>
      onSubmit={onSubmit}
      initialValues={{ name: "", branchId: "", code: "" }}
    >
      <Form>
        <div
          className={css({
            maxWidth: "800px",
            margin: "0 auto",
          })}
        >
          <h1 className={css({ marginBottom: "1rem" })}>Přidat baštu</h1>

          <BastiaForm />
          <ButtonForm style={{ width: "100%" }}>Přidat</ButtonForm>
        </div>
      </Form>
    </Formik>
  );
}
