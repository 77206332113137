import {
  ApolloClient,
  ApolloLink,
  ApolloProvider as Apollo,
  HttpLink,
  InMemoryCache,
} from "@apollo/client";
import { useAuth0 } from "@auth0/auth0-react";
import React, { PropsWithChildren, useEffect, useState } from "react";

const httpLink = new HttpLink({ uri: "/graphql" });

export function UnauthorizedApolloProvider({
  children,
}: PropsWithChildren<{}>) {
  const client = new ApolloClient({
    cache: new InMemoryCache(),
    link: httpLink,
  });

  return <Apollo client={client}>{children}</Apollo>;
}

export default function ApolloProvider({ children }: PropsWithChildren<{}>) {
  const { getIdTokenClaims, isAuthenticated } = useAuth0();
  const [token, setToken] = useState<string | undefined>(undefined);

  useEffect(() => {
    const set = async () => {
      const t = await getIdTokenClaims();
      setToken(t.__raw);
    };
    if (isAuthenticated) {
      set();
    }
  }, [isAuthenticated]);

  if (!isAuthenticated) {
    return <>{children}</>;
  }

  const authLink = new ApolloLink((operation, forward) => {
    // NO BEARER prefix!
    operation.setContext({
      headers: {
        authorization: token || "",
      },
    });

    return forward(operation);
  });

  const client = new ApolloClient({
    cache: new InMemoryCache(),
    link: authLink.concat(httpLink),
  });

  return <Apollo client={client}>{children}</Apollo>;
}
