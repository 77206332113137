import {
  Modal as BaseModal,
  ModalBody,
  ModalHeader,
  ROLE,
  SIZE,
} from "baseui/modal";
import * as React from "react";

export interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  header: string;
}

export default function Modal(props: React.PropsWithChildren<ModalProps>) {
  return (
    <BaseModal
      onClose={props.onClose}
      closeable
      isOpen={props.isOpen}
      animate
      autoFocus
      size={SIZE.auto}
      role={ROLE.dialog}
    >
      <ModalHeader>{props.header}</ModalHeader>
      <ModalBody>{props.children}</ModalBody>
      {/* <ModalFooter>
        <ModalButton kind={ButtonKind.tertiary}>Cancel</ModalButton>
        <ModalButton>Okay</ModalButton>
      </ModalFooter> */}
    </BaseModal>
  );
}
