import React, { useContext, useEffect, useState } from "react";

export const Mapkit = React.createContext<any>(undefined);

interface MapkitContexProps {
  mapkit: typeof mapkit;
  map: mapkit.Map | undefined;
  setMap: (m: mapkit.Map) => void;
}

export function useMap(mapRef?: React.MutableRefObject<HTMLDivElement | null>) {
  const { mapkit, map, setMap } = useContext<MapkitContexProps>(Mapkit);

  useEffect(() => {
    if (mapRef?.current) {
      const map = new mapkit.Map(mapRef.current);
      map.annotationForCluster = function (clusterAnnotation: any) {
        clusterAnnotation.color = clusterAnnotation.memberAnnotations[0].color;
      };
      setMap(map);
    }
  }, [mapRef]);

  return {
    map,
    mapkit,
  };
}

export const MapkitProvider = ({ children }: any) => {
  const [map, setMap] = useState<mapkit.Map | undefined>(undefined);

  new Promise((resolve, reject) => {
    (window as any).mapkit.init({
      authorizationCallback: async (cb: (token: string) => void) => {
        const result = await fetch("/apple-jwt");
        const { token } = await result.json();
        cb(token);
        resolve(token);
      },
      language: "cs",
    });
  });

  return (
    <Mapkit.Provider
      value={{
        mapkit: (window as any).mapkit,
        map,
        setMap,
      }}
    >
      {children}
    </Mapkit.Provider>
  );
};

export default MapkitProvider;
