import { gql, useMutation, useQuery } from '@apollo/client'
import { Button, ButtonAppearance, NavButton } from '@werein/components'
import { StatefulMenu } from 'baseui/menu'
import { StatefulPopover } from 'baseui/popover'
import { endOfDay, parseISO } from 'date-fns'
import { isBefore, startOfDay } from 'date-fns/esm'
import { sort } from 'ramda'
import React, { Fragment, useEffect, useState } from 'react'
import * as Icons from 'react-feather'
import { useHistory, useParams } from 'react-router'
import { useStyletron } from 'styletron-react'
import { useUser } from '../../app/components/layout'
import Modal from '../../components/modal'
import DatePickerField from '../../form/date-picker-field'
import { Pond, PondCycleEntry, PondOverview as PondOverviewType } from '../../models'
import { margin } from '../../utils/css'
import { sortByDate } from '../../utils/date'
import PondCloseCycle from '../close-cycle'
import CreateEntryForm from '../create-entry-form'
import EditEntryForm from '../edit-entry-form'
import PondOverview from '../overview'

const GET_POND = gql`
  query pond($id: String!, $date: String) {
    pond(id: $id) {
      _id
      name
      landArea
      code
      waterArea
      deleted
      bastia {
        branch {
          _id
          company {
            _id
          }
        }
      }
      cycles {
        _id
        name
        closedAt
        overview {
          category
          weight
          amount
        }
      }
      cycle {
        _id
        closedAt
        entries {
          _id
          date
          weight
          amount
          category
          massUnit
          note
          entryId
          pond {
            name
            _id
          }
        }
        estimates {
          _id
          date
          weight
          amount
          category
          massUnit
          note
        }
      }
    }
    pondCycleOverview(pondId: $id, date: $date) {
      category
      weight
      amount
      plusAmount
      minusAmount
      plusWeight
      minusWeight
      estimate {
        weight
        amount
      }
    }
  }
`

const DELETE_POND = gql`
  mutation deletePond($id: String!) {
    deletePond(id: $id) {
      _id
      bastia {
        _id
      }
    }
  }
`

export default function PondScreenIndex() {
  const [css] = useStyletron()
  const { push } = useHistory()
  const [isEditing, setEditing] = useState<PondCycleEntry | null>(null)
  const { id } = useParams<{ id: string; cycle?: string }>()
  const [date, setDate] = useState<Date | undefined>(undefined)
  const [deletePond] = useMutation(DELETE_POND)
  const { can } = useUser()
  const { error, loading, data, refetch } = useQuery<{
    pond: Pond
    pondCycleOverview: PondOverviewType[]
  }>(GET_POND, {
    variables: { id, date },
  })

  useEffect(() => {
    refetch({ id })
  }, [id])

  const onDelete = async () => {
    const confirmed = window.confirm('Doopravdy chcete smazat položku?')

    if (confirmed) {
      try {
        const { data } = await deletePond({
          variables: { id },
        })
        push(`/bastia/${data.deletePond.bastia._id}`)
      } catch (error) {
        console.log(error)
      }
    }
  }

  if (error) {
    return <>Error</>
  }

  if (loading) {
    return <>...</>
  }

  if (!data) {
    return <>No results found</>
  }

  const estimates = data.pond.cycle.estimates.filter((e) => (date ? isBefore(startOfDay(parseISO(e.date)), endOfDay(date)) : true))

  const highlightedCss = css({
    fontSize: '2rem',
  })

  const hasRight = can({ pond: data.pond })
  const sorted = sort(sortByDate, data.pond.cycle.entries).reverse()
  const historicCycles = data.pond.cycles.filter((c) => c.closedAt)
  return (
    <div>
      <div
        className={css({
          display: 'flex',
          justifyContent: 'space-between',
          ...margin('1rem 0'),
        })}
      >
        <div>
          <h1>{data.pond.name}</h1>
        </div>

        <div
          className={css({
            display: 'grid',
            gap: '1rem',
            '@media (min-width: 720px)': {
              gridTemplateColumns: 'repeat(2, 1fr)',
            },
          })}
        >
          <div>
            <div className={highlightedCss}>
              {data.pond.landArea.toFixed(4)}
              <small
                className={css({
                  fontSize: '1.5rem',
                })}
              >
                ha
              </small>
            </div>
            <div>Katastrální výměra</div>
          </div>
          <div>
            <div className={highlightedCss}>
              {data.pond.waterArea.toFixed(4)}
              <small
                className={css({
                  fontSize: '1.5rem',
                })}
              >
                ha
              </small>
            </div>
            <div>Vodní plocha</div>
          </div>
        </div>
      </div>

      <div
        className={css({
          display: 'grid',
          gridTemplateColumns: 'repeat(4, 1fr)',
          gap: '1rem',
          '@media print': {
            display: 'none',
          },
        })}
      >
        {!data.pond.deleted && hasRight && (
          <>
            <NavButton appearance={ButtonAppearance.secondary} to={`/pond/${id}/edit`} icon={<Icons.Edit />}>
              Upravit
            </NavButton>
            <Button appearance={ButtonAppearance.secondary} onClick={onDelete} icon={<Icons.X />}>
              Smazat
            </Button>
            <NavButton appearance={ButtonAppearance.secondary} to={`/pond/${id}/estimate`} icon={<Icons.BookOpen />}>
              Odhady
            </NavButton>
            <PondCloseCycle id={id} reload={refetch} />
          </>
        )}

        {historicCycles.length > 0 ? (
          <StatefulPopover
            content={() => (
              <StatefulMenu
                onItemSelect={({ item }) => push(`/pond/${id}/${item.id}`)}
                items={historicCycles.map((i) => ({
                  label: i.name,
                  id: i._id,
                }))}
              />
            )}
            returnFocus
            autoFocus
          >
            <div
              className={css({
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                background: 'rgb(246, 246, 246)',
                borderRadius: '2rem',
                cursor: 'pointer',
              })}
            >
              <Icons.Archive />
            </div>
          </StatefulPopover>
        ) : (
          <Fragment />
        )}
      </div>

      <div
        className={css({
          marginTop: '1rem',
          '@media print': {
            display: 'none',
          },
        })}
      >
        <DatePickerField clearable label="Zobrazit přehled ke dni" value={date} onChange={(d: any) => setDate(d)} />
      </div>

      {data.pondCycleOverview && <PondOverview items={data.pondCycleOverview} />}

      {!data.pond.deleted && hasRight && <CreateEntryForm pondId={id} cycleId={data.pond.cycle._id} refetch={refetch} />}

      {data.pond.deleted && <div>Tento záznam byl archivován. Není možné provádět žádné změny</div>}

      <div
        role="grid"
        className={css({
          ...margin('1rem 0'),
          display: 'grid',
          gridTemplateColumns: '2fr 2fr 2fr 2fr 2fr 2fr 1fr 1fr',
        })}
      >
        <div
          role="row"
          className={css({
            display: 'contents',
            '@media (max-width: 720px)': {
              display: 'none',
            },
          })}
        >
          <div
            className={css({
              fontWeight: 'bold',
              padding: '0.5rem 0',
              borderBottom: 'solid 1px var(--black-20)',
              lineHeight: '2rem',
            })}
          >
            Datum
          </div>
          <div
            className={css({
              fontWeight: 'bold',
              padding: '0.5rem 0',
              borderBottom: 'solid 1px var(--black-20)',
              lineHeight: '2rem',
            })}
          >
            Kategorie
          </div>
          <div
            className={css({
              fontWeight: 'bold',
              padding: '0.5rem 0',
              borderBottom: 'solid 1px var(--black-20)',
              lineHeight: '2rem',
            })}
          >
            Počet ks
          </div>
          <div
            className={css({
              fontWeight: 'bold',
              padding: '0.5rem 0',
              borderBottom: 'solid 1px var(--black-20)',
              lineHeight: '2rem',
            })}
          >
            Hmotnost
          </div>
          <div
            className={css({
              fontWeight: 'bold',
              padding: '0.5rem 0',
              borderBottom: 'solid 1px var(--black-20)',
              lineHeight: '2rem',
            })}
          >
            Hmotnost/kus
          </div>
          <div
            className={css({
              fontWeight: 'bold',
              padding: '0.5rem 0',
              borderBottom: 'solid 1px var(--black-20)',
              lineHeight: '2rem',
            })}
          >
            Zdroj
          </div>
          <div
            className={css({
              fontWeight: 'bold',
              padding: '0.5rem 0',
              borderBottom: 'solid 1px var(--black-20)',
              lineHeight: '2rem',
            })}
          >
            Poznámka
          </div>
          <div
            className={css({
              fontWeight: 'bold',
              padding: '0.5rem 0',
              borderBottom: 'solid 1px var(--black-20)',
              lineHeight: '2rem',
            })}
          />
        </div>
        {isEditing && hasRight && (
          <Modal header="" isOpen onClose={() => setEditing(null)}>
            <EditEntryForm
              onClose={() => setEditing(null)}
              pondId={data.pond._id}
              cycleId={data.pond.cycle._id}
              id={isEditing._id}
              refetch={refetch}
              inverseId={isEditing.entryId || undefined}
              inversePondId={isEditing.pond?._id}
              inverseCycleId={undefined}
              initialValues={{
                in: isEditing.amount > 0,
                date: parseISO(isEditing.date),
                weight: isEditing.weight > 0 ? isEditing.weight : isEditing.weight * -1,
                amount: isEditing.amount > 0 ? isEditing.amount : isEditing.amount * -1,
                pondId: isEditing.pond?._id,
                massUnit: isEditing.massUnit,
                category: isEditing.category,
              }}
            />
          </Modal>
        )}
        {sorted.map((e) => {
          return (
            <div
              role="row"
              className={css({
                display: 'contents',
              })}
            >
              <div
                className={css({
                  padding: '0.5rem 0',
                  borderBottom: 'solid 1px var(--black-20)',
                  lineHeight: '2rem',
                })}
              >
                {parseISO(e.date).toLocaleDateString()}
              </div>
              <div
                className={css({
                  padding: '0.5rem 0',
                  borderBottom: 'solid 1px var(--black-20)',
                  lineHeight: '2rem',
                })}
              >
                {e.category}
              </div>
              <div
                className={css({
                  padding: '0.5rem 0',
                  borderBottom: 'solid 1px var(--black-20)',
                  lineHeight: '2rem',
                })}
              >
                {e.amount} ks
              </div>
              <div
                className={css({
                  padding: '0.5rem 0',
                  borderBottom: 'solid 1px var(--black-20)',
                  lineHeight: '2rem',
                })}
              >
                {e.weight} kg
              </div>
              <div
                className={css({
                  padding: '0.5rem 0',
                  borderBottom: 'solid 1px var(--black-20)',
                  lineHeight: '2rem',
                })}
              >
                {e.massUnit.toFixed(3)} kg/ks
              </div>

              <div
                className={css({
                  padding: '0.5rem 0',
                  borderBottom: 'solid 1px var(--black-20)',
                  lineHeight: '2rem',
                })}
              >
                {e.pond && (
                  <NavButton appearance={ButtonAppearance.secondary} style={{ width: '100%' }} to={`/pond/${e.pond._id}`}>
                    {e.pond.name}
                  </NavButton>
                )}
              </div>
              <div
                className={css({
                  padding: '0.5rem',
                  borderBottom: 'solid 1px var(--black-20)',
                  lineHeight: '2rem',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  cursor: 'pointer',
                })}
              >
                <StatefulPopover
                  content={() => (
                    <div
                      className={css({
                        padding: '20px',
                      })}
                    >
                      {e.note}
                    </div>
                  )}
                  returnFocus
                  autoFocus
                >
                  {e.note}
                </StatefulPopover>
              </div>
              <div
                className={css({
                  padding: '0.5rem 1rem',
                  borderBottom: 'solid 1px var(--black-20)',
                  lineHeight: '2rem',
                  justifyContent: 'center',
                  display: 'grid',
                  gridTemplateColumns: '1fr 1fr',
                  gap: '0.5rem',
                })}
              >
                <Button icon={<Icons.Edit size="16px" />} disabled={!hasRight} size="compact" onClick={() => setEditing(e)} />

                {/* <Button size="medium">
                  <Icons.X size="16px" />
                </Button> */}
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}
