import { gql, useMutation } from "@apollo/client";
import { Form, Formik, FormikHelpers } from "formik";
import React from "react";
import { useStyletron } from "styletron-react";
import * as Yup from "yup";
import Link from "../components/link";
import { PondCycleEntryInput } from "../models";
import CycleForm from "./cycle-form";

export const validationSchema = Yup.object().shape({
  category: Yup.string().required("Doplňte kagetorii"),
});

const UPDATE_POND_CYCLE_ENTRY = gql`
  mutation updatePondCycleEntry(
    $pondId: String!
    $cycleId: String!
    $id: String!
    $inverseId: String
    $inversePondId: String
    $inverseCycleId: String
    $input: PondCycleEntryInput!
  ) {
    updatePondCycleEntry(
      pondId: $pondId
      cycleId: $cycleId
      id: $id
      inverseId: $inverseId
      inversePondId: $inversePondId
      inverseCycleId: $inverseCycleId
      input: $input
    ) {
      _id
    }
  }
`;

const DELETE_POND_CYCLE_ENTRY = gql`
  mutation deletePondCycleEntry(
    $pondId: String!
    $cycleId: String!
    $id: String!
    $inverseId: String
    $inversePondId: String
    $inverseCycleId: String
  ) {
    deletePondCycleEntry(
      pondId: $pondId
      cycleId: $cycleId
      id: $id
      inverseId: $inverseId
      inversePondId: $inversePondId
      inverseCycleId: $inverseCycleId
    ) {
      _id
    }
  }
`;

interface CreateEntryFormProps {
  onClose: () => void;
  pondId: string;
  cycleId: string;
  id: string;
  inverseId?: String;
  inversePondId?: String;
  inverseCycleId?: String;
  refetch: any;
  initialValues: {
    in: boolean;
    date: Date;
    weight: number;
    amount: number;
    pondId?: string;
    massUnit: number;
    category: string;
  };
}

export default function EditEntryForm(props: CreateEntryFormProps) {
  const [updatePondCycleEntry] = useMutation(UPDATE_POND_CYCLE_ENTRY);
  const [deletePondCycleEntry] = useMutation(DELETE_POND_CYCLE_ENTRY);

  const { pondId, cycleId, id, inverseCycleId, inversePondId, inverseId } =
    props;
  const [css] = useStyletron();

  const onSubmit = async (
    values: PondCycleEntryInput & { in: boolean },
    formikHelpers: FormikHelpers<PondCycleEntryInput & { in: boolean }>
  ) => {
    const amount = values.in
      ? Math.abs(values.amount)
      : -Math.abs(values.amount);
    const weight = values.in
      ? Math.abs(values.weight)
      : -Math.abs(values.weight);

    const input: PondCycleEntryInput = {
      pondId: values.pondId,
      amount,
      date: values.date,
      weight,
      massUnit: values.massUnit,
      category: values.category,
      note: values.note,
    };

    try {
      const { data } = await updatePondCycleEntry({
        variables: {
          input,
          pondId,
          cycleId,
          id,
          inverseCycleId,
          inversePondId,
          inverseId,
        },
      });

      await props.refetch();
      formikHelpers.resetForm();
      props.onClose();
    } catch (error) {
      formikHelpers.setStatus("ERROR");
    }
  };

  const onDelete = async () => {
    const confirmed = window.confirm("Doopravdy chcete smazat položku?");

    if (confirmed) {
      try {
        await deletePondCycleEntry({
          variables: {
            pondId,
            cycleId,
            id,
            inverseCycleId,
            inversePondId,
            inverseId,
          },
        });
        await props.refetch();
        props.onClose();
      } catch (error) {
        console.log(error);
      }
    }
  };

  if (inversePondId && !inverseId) {
    return (
      <div>
        Prosím proveďte úpravu záznamu{" "}
        <Link target="_blank" to={`/pond/${inversePondId}`}>
          zde
        </Link>
      </div>
    );
  }

  return (
    <Formik
      onSubmit={onSubmit as any}
      validationSchema={validationSchema}
      initialValues={props.initialValues}
    >
      <Form
        className={css({
          position: "sticky",
          top: 0,
          borderBottom: "solid 1px gray",
        })}
      >
        <CycleForm edit onDelete={onDelete} />
      </Form>
    </Formik>
  );
}
